import { Button, Card, Container, Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
// import { getSubscriptionById } from '../../../services/SubscriptionService';
// import { getCompanyById } from '../../../services/CompanyService';
import CancelSubscriptionDialog from './CancelSubscriptionDialog';
import { castPayPalStringToDate } from '../../../utils/castPayPalStringToDate';
import useLocales from '../../../hooks/useLocales';

const PaymentTab = () => {
  const { currentCompany } = useSelector((state) => state.company);
  const [openCancelSubscriptionDialog, setOpenCancelSubscriptionDialog] = useState(false);
  const { translate } = useLocales();
  // const [company, setCompany] = useState({});
  // const [subscription, setSubscription] = useState({});

  const hasSubscription = currentCompany?.subscription?.planName;
  const hasSubscriptionActive = currentCompany?.subscription?.renewal;

  const history = useHistory();

  const handleClickOpen = () => {
    setOpenCancelSubscriptionDialog(true);
  };

  const handleClose = () => {
    setOpenCancelSubscriptionDialog(false);
  };

  const changePlan = () => {
    history.push('/dashboard/payment');
  };

  const cancelPlan = () => {
    handleClickOpen();
  };

  // useEffect(() => {
  // const fetchSubscription = async (subscriptionId) => {
  //   await getSubscriptionById(currentCompany.id, subscriptionId).then((data) => {
  //     setSubscription(data);
  //     console.log('subscription', data);
  //   });
  // };

  //   const fetchCompany = async () => {
  //     await getCompanyById(currentCompany.id).then((data) => {
  //       setCompany(data);
  //       // fetchSubscription(data.subscription.subscriptionId);

  //       const currentDate = new Date();

  //       const startedAt = castPayPalStringToDate(data.subscription.startedAt);

  //       console.log('startedAt', startedAt);
  //       console.log('currentDate', currentDate);

  //       console.log('moment', moment(startedAt));
  //       console.log('typeof startedAt', typeof startedAt);

  //       console.log('typeof new Date', typeof currentDate);

  //       console.log('>', startedAt > currentDate);
  //       console.log('<', startedAt < currentDate);
  //     });
  //   };

  //   fetchCompany();
  // }, [currentCompany.id]);

  return (
    <Card sx={{ p: 2, pb: 4 }}>
      <Container>
        <h1>{translate('Table.button39')}</h1>
        <p>
          {translate('Table.button44')} <strong>{currentCompany.name}</strong> {translate('Table.button43')}{' '}
          <strong>{hasSubscription ? currentCompany.subscription.planName : translate('Table.button47')}</strong>
        </p>

        {hasSubscription && (
          <div>
            <p>
              {translate('Table.button40')}{' '}
              {moment(castPayPalStringToDate(currentCompany.subscription.startedAt)).format('DD/MM/YYYY')}
            </p>
            <p>
              {hasSubscriptionActive ? translate('Table.button41') : translate('Table.button42')}
              {moment(castPayPalStringToDate(currentCompany.subscription.finishAt)).format('DD/MM/YY')}
            </p>
          </div>
        )}

        <br />

        <Grid sx={{ flexGrow: 1 }} container spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="center" spacing={2}>
              <Button variant="contained" onClick={changePlan}>
                {translate('Table.button45')}
              </Button>
              {hasSubscriptionActive && (
                <Button variant="contained" onClick={cancelPlan} sx={{ ml: 2 }}>
                  {translate('Table.button46')}
                </Button>
              )}

              <CancelSubscriptionDialog
                open={openCancelSubscriptionDialog}
                handleClose={handleClose}
                company={currentCompany}
                subscription={currentCompany.subscription}
              />
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Card>
  );
};

export default PaymentTab;
