/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, Link as RouterLink } from 'react-router-dom';

import PropTypes from 'prop-types';

import Tooltip from '@material-ui/core/Tooltip';
import {
  Box,
  Card,
  Checkbox,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Typography,
  MenuItem,
  IconButton,
  TableContainer,
  TablePagination,
  Grid,
  CardHeader,
  Dialog,
  DialogContent,
  TableHead,
} from '@material-ui/core';
import ApartmentIcon from '@mui/icons-material/Apartment';
import AddHomeWorkIcon from '@mui/icons-material/AddHomeWork';
import EditIcon from '@mui/icons-material/Edit';
import AddModeratorIcon from '@mui/icons-material/AddModerator';
import { Accordion, AccordionDetails, AccordionSummary, Button, List, ListItem, ListItemText } from '@mui/material';
import moment from 'moment';
import { Icon } from '@iconify/react';

import { useSelector } from 'react-redux';
import { MCircularProgress } from '../../@material-extend';
import Scrollbar from '../../Scrollbar';
import SearchNotFound from '../../SearchNotFound';
import { ProductListHead } from '../../e-commerce/product-list';
import ToolbarComponent from '../Toolbar/ToolbarComponent';
import MoreMenu from '../Menu/MoreMenu';
import Label from '../../Label';
import { dateHasNoExpired, hasSubscription } from '../../../utils/validatePayments';
import OrganizationModification from '../../../views/Organization/OrganizationModification';
import CustomDialog from '../Dialog/CustomDialog'; // Usa CustomDialog en lugar de Dialog

const TableSubItemsList = ({
  title,
  TABLE_HEAD,
  getDocuments,
  handleDelete,
  nameInSpanish,
  nameInEnglish,
  urlToDownloadSheet,
  isMasculine,
  needMassiveCharge,
  isLoading,
  documents,
  isList,
  handleCreateSubItem,
  handleClickItem,
  handleClickSubItem,
  tableStyle,
  dontNeedCheckBox,
  dontNeedCreateButton,
  selectedCompany,
  setSelectedCompany,
  needDuplicateButton = true,
  handleCreateItem,
  handleEditItem,
  handleSelectedCompany,
  handleDuplicateItem,
  needSettings,
  anotherButton,
  anotherTextButton,
  nameDocument,
  searchList,
  onRowData,
}) => {
  const history = useHistory();

  const { userProfile } = useSelector((state) => state.user);

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [selectedId, setSelectedId] = useState('');
  const openDialog = () => setOpenModal(true);
  const closeDialog = () => setOpenModal(false);
  const [selectedRow, setSelectedRow] = useState(null);
  console.log('selectedId', handleEditItem);
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { currentCompany } = useSelector((state) => state.company);
  console.log('currentCompany', currentCompany);
  const [orderBy, setOrderBy] = useState('companyName');
  const [openModal, setOpenModal] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { currentBranch } = useSelector((state) => state.company);

  console.log('isDialogOpen');

  const location = useLocation();

  const handleRedirect = () => {
    history.push('/dashboard/payment');
  };

  // Función para abrir el diálogo usando history.push
  const handleOpenDialog = (e, row) => {
    e.stopPropagation();
    console.log('handleOpenDialog row', row);
    setSelectedRow(row);
    setIsDialogOpen(true); // Open the dialog
    // Update the URL
  };

  // Function to close the dialog
  const handleCloseDialog = () => {
    setIsDialogOpen(false); // Close the dialog
    // history.push(`/dashboard/organization/${selectedRow?.id}/settings`); // Update the URL back
  };

  // Effect to check if the dialog should open based on the URL
  // useEffect(() => {
  //   if (location.pathname.includes('/settings') && new URLSearchParams(location.search).get('dialog') === 'open') {
  //     setIsDialogOpen(true);
  //   }
  // }, [location]);

  const handleRedirect2 = (row, el) => {
    console.log('rowrow', row);
    if (row) {
      // Asegúrate de que selectedCompanytable tiene un valor
      history.push(`/dashboard/organization/${row.id}/settings`); // Redirige a la página deseada
    } else {
      console.error('No se ha seleccionado ninguna compañía'); // En caso de que no haya compañía seleccionada
    }
  };

  console.log('handleSelectedCompany127111', handleClickItem);

  const filterDocuments = applySortFilter(
    documents,
    getComparator(order, orderBy),
    filterName,
    nameDocument,
    searchList
  );
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - documents.length) : 0;
  const isProductNotFound = filterDocuments.length === 0;

  const [expanded, setExpanded] = useState('');

  const rowStyle = {
    cursor: 'pointer',
    background: '#F4F6F8',
    height: 10,
  };

  useEffect(() => {
    handleClickItem(currentCompany);
    setExpanded(currentCompany.id); // Esto asegura que el Accordion correspondiente se expanda
  }, [currentCompany.id]);

  console.log('expanded', expanded);

  const handleClick = (event, name, id) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);

    setSelectedId(id);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handle = (handleSelectedCompany) => {
    setSelectedId(handleSelectedCompany);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = documents.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const onHandleClickSubItem = (subItem) => {
    handleClickSubItem(subItem);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (getDocuments) getDocuments();
  }, [getDocuments]);

  const renderSwitchName = (row, el) => {
    onRowData && onRowData(row);
    console.log('row, el', 'row', row, 'el', el);
    /* Si es una fecha muestrela como tal, si es un array, normal */
    // {el.isDate ? moment(row[el.id]).fromNow() : el.isDate ? row[el.id] : row[el.id]}

    if (el.isDate) {
      return moment(row[el.id]).calendar();
    }
    if (el.isArray) {
      return row[el.isArray][el.id];
    }
    return row[el.id];
  };

  const onClickNormalItem = (row, id) => {
    if (handleClickItem) {
      handleClickItem(currentCompany);
      return;
    }
    history.push(`/dashboard/${nameInEnglish}/${id}/detail`);
  };

  // eslint-disable-next-line arrow-body-style
  const renderNormalItem = (row, el, id) => {
    return (
      <TableCell
        key={`${id}-${el.id}`}
        onClick={() => {
          onClickNormalItem(row, id);
        }}
        align="left"
      >
        {renderSwitchName(currentCompany, el)}
      </TableCell>
    );
  };

  const getSubscriptionStatus = (subscription) => {
    // no tiene suscripción
    if (!hasSubscription(subscription)) {
      return { colorPlan: 'default', textPlan: 'sin subscripción' };
    }
    // suscripción no ha vencido
    if (dateHasNoExpired(subscription)) {
      return { colorPlan: 'secondary', textPlan: `plan ${subscription.planName}` };
    }
    // plan vencido
    return { colorPlan: 'error', textPlan: 'plan vencido' };
  };

  // eslint-disable-next-line arrow-body-style
  const renderListItems = (row, el, id) => {
    onRowData && onRowData(row);
    const isOwner = row.rootUser === userProfile.uid;
    // const colorPlan = 'default';
    // const textPlan = 'Sin plan';
    // console.log('row', row);
    const { colorPlan, textPlan } = getSubscriptionStatus(row?.subscription);

    return (
      <AccordionDetails>
        <List key={`list2-${id}`} component="nav" aria-label="mailbox folders" sx={{ ml: -4 }}>
          {row[isList]
            ? Object.keys(row[isList]).map((el, idx) => (
                <ListItem
                  key={`item2-${id}-${idx}`}
                  onClick={() => {
                    onHandleClickSubItem({ id: el, ...row[isList][el] });
                  }}
                  sx={[
                    { marginLeft: '0px' }, // Agrega espacio entre elementos
                    {
                      backgroundColor: currentBranch.id === el ? '#ECECEC' : 'transparent',
                      borderRadius: 1,
                      width: 178,

                      padding: '10px',
                      '&:hover': {
                        color: 'black',
                        backgroundColor: currentBranch.id === el ? '#ECECEC' : '#f7f6f6',
                        borderRadius: 1,
                      },
                    },
                  ]}
                >
                  <ListItemText
                    key={`text ${id}`}
                    primary={row[isList][el].name}
                    primaryTypographyProps={{
                      fontSize: '13px', // Ajusta el tamaño aquí
                    }}
                    sx={{
                      ml: 1,
                    }}
                  />
                </ListItem>
              ))
            : null}
        </List>
      </AccordionDetails>
    );
  };

  const renderSwitchItem = (row, el, id) => {
    if (isList && el.id !== 'typeUser') {
      return renderListItems(row, el, id);
    }
    return renderNormalItem(row, el, id);
  };

  const validateRows = () => {
    if ((documents.length - 1) % rowsPerPage === 0 && page !== 0) {
      setPage(page - 1);
    }
  };

  // eslint-disable-next-line arrow-body-style
  const renderMoreMenu = (row, el, id) => {
    console.log('rowrow', id);
  };

  return (
    <Box>
      {filterDocuments.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
        const { id } = row;
        const isItemSelected = selected.indexOf(id) !== -1;

        return (
          <MenuItem
            key={id}
            disableTouchRipple // Desactiva el efecto ripple al hacer clic
            sx={{
              typography: 'body2',
              py: 1,
              px: 2.5,
              '&:hover': {
                backgroundColor: 'transparent', // Desactiva el hover
              },
              '&:active': {
                backgroundColor: 'transparent', // Desactiva el estilo al hacer clic
              },
              '&:focus': {
                backgroundColor: 'transparent', // Desactiva el foco
              },
            }}
          >
            {TABLE_HEAD.map((el) => (el.id !== '' ? renderSwitchItem(row, el, id) : renderMoreMenu(row, el, id)))}
          </MenuItem>
        );
      })}
    </Box>
  );
};

// -----------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

const getFlagInSearchList = (_user, query, searchList) => {
  let flag = false;
  searchList.forEach((nameDocument) => {
    let doc;
    if (nameDocument.includes('.')) {
      const obj = nameDocument.split('.');
      doc = _user[obj[0]][obj[1]];
    } else {
      doc = _user[nameDocument];
    }
    flag = flag || `${doc}`.toLowerCase().indexOf(query.toLowerCase()) !== -1;
  });
  return flag;
};

function applySortFilter(array, comparator, query, nameDocument, searchList) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => {
      if (searchList) {
        return getFlagInSearchList(_user, query, searchList);
      }
      // console.log(_user);
      return _user[nameDocument]?.toLowerCase().indexOf(query.toLowerCase()) !== -1;
    });
  }
  return stabilizedThis.map((el) => el[0]);
}

TableSubItemsList.propTypes = {
  title: PropTypes.string,
  TABLE_HEAD: PropTypes.array,
  getDocuments: PropTypes.func,
  handleDelete: PropTypes.func,
  nameInSpanish: PropTypes.string,
  nameInEnglish: PropTypes.string,
  urlToDownloadSheet: PropTypes.string,
  isMasculine: PropTypes.bool,
  needMassiveCharge: PropTypes.bool,
  isLoading: PropTypes.bool,
  documents: PropTypes.array,
  isList: PropTypes.string,
  handleCreateSubItem: PropTypes.func,
  handleClickItem: PropTypes.func,
  selectedCompany: PropTypes.func,
  setSelectedCompany: PropTypes.func,
  handleClickSubItem: PropTypes.func,
  tableStyle: PropTypes.object,
  dontNeedCheckBox: PropTypes.bool,
  dontNeedCreateButton: PropTypes.bool,
  needDuplicateButton: PropTypes.bool,
  handleCreateItem: PropTypes.func,
  handleEditItem: PropTypes.func,
  handleSelectedCompany: PropTypes.func,
  handleDuplicateItem: PropTypes.func,
  needSettings: PropTypes.bool,
  anotherButton: PropTypes.func,
  anotherTextButton: PropTypes.string,
  nameDocument: PropTypes.string,
  searchList: PropTypes.array,
  onRowData: PropTypes.func,
};

export default TableSubItemsList;
