import {
  Box,
  Button,
  Modal,
  Table,
  TableRow,
  Typography,
  TableBody,
  TableContainer,
  TableCell,
  TableHead,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Scrollbar from '../../Scrollbar';
import useLocales from '../../../hooks/useLocales';

const listItems = (list) => (
  <ul>
    {list.map((elm, index) => (
      <li key={index}>{JSON.stringify(elm).replaceAll('"', '')}</li>
    ))}
  </ul>
);
const CompanyLogModal = ({ openModal, setOpenModal, contentModal }) => {
  const [fieldsModified, setFieldsModified] = useState([]);
  const { translate } = useLocales();

  useEffect(() => {
    setFieldsModified(contentModal);
  }, [contentModal]);

  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Modal
      open={openModal}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pl: 20,
        pr: 20,
      }}
      BackdropProps={{
        transitionDuration: 2000,
      }}
    >
      <Box
        sx={{
          background: 'white',
          width: '80%',
          position: 'absolute',
          maxWidth: '800px', // Ancho máximo
          maxHeight: '90vh', // Altura máxima
          overflowY: 'auto', // Desplazamiento si el contenido es grande
          p: 3,
        }}
      >
        <Typography gutterBottom variant="h4">
          {translate('detail.message')}
        </Typography>

        <Scrollbar>
          <TableContainer sx={{ minWidth: 500 }}>
            <Table>
              {/* <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  hasNoCheckBox={false}
                  rowCount={clients.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  // onSelectAllClick={() => {}}
                /> */}
              <TableHead>
                <TableRow>
                  <TableCell align="left"> </TableCell>
                  {<TableCell align="left">{translate('detail.back')}</TableCell>}
                  {<TableCell align="left">{translate('detail.button')}</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {fieldsModified.map((elm, index) => (
                  <TableRow key={index}>
                    <TableCell align="left">{elm.field}</TableCell>
                    <TableCell align="left">
                      {(typeof elm.oldValue === 'object' && listItems(elm.oldValue)) || (elm.oldValue ?? ' ')}
                    </TableCell>
                    <TableCell align="left">
                      {(typeof elm.updatedValue === 'object' && listItems(elm.updatedValue)) ||
                        (elm.updatedValue ?? ' ')}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>

        <Box sx={{ mt: 3 }}>
          {/* <Button variant="contained" onClick={onConfirm} sx={{ mr: 2 }}>
            Confirm
          </Button> */}
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

CompanyLogModal.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  contentModal: PropTypes.any,
};

export default CompanyLogModal;
