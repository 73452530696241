import firebase from 'firebase/app';
import { createLog } from '../redux/slices/log';

const invoceViewBaseUrl = 'invoceViews_branch';

export async function getInvoiceViewsByBranch(branchId) {
  return firebase
    .database()
    .ref(`/${invoceViewBaseUrl}/${branchId}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : {}))
    .catch((error) => {
      console.error(error);
    });
}

export async function getInvoiceViewById(branchId, invoiceViewId) {
  return firebase
    .database()
    .ref(`/${invoceViewBaseUrl}/${branchId}/${invoiceViewId}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? snapshot.val() : {}))
    .catch((error) => {
      console.error(error);
    });
}

export const createNewInvoiceView = async (invoiceView, branchId) => {
  try {
    const log = await createLog('invoceView', 'create', invoiceView.id, [], invoiceView);

    const newInvoiceView = {
      ...invoiceView,
      log: { ...(invoiceView.log || {}), [log]: true },
    };

    const rootRef = firebase.database().ref('/');
    const news = rootRef.child(`${invoceViewBaseUrl}/${branchId}/${invoiceView.id}`);
    await news.set(newInvoiceView);
  } catch (error) {
    console.error(error);
  }
};

export const updateInvoiceView = async (invoiceView, branchId) => {
  const currentInvoiceView = await getInvoiceViewById(branchId, invoiceView.id);

  const log = await createLog('invoceView', 'edit', invoiceView.id, currentInvoiceView, invoiceView);

  const newInvoiceView = {
    ...invoiceView,
    log: { ...(invoiceView.log || {}), [log]: true },
  };

  const updates = {};
  updates[`${invoceViewBaseUrl}/${branchId}/${invoiceView.id}`] = newInvoiceView;

  firebase.database().ref().update(updates);
};

export const deleteInvoiceView = async (branchId, invoiceViewId) => {
  const rootRef = firebase.database().ref('/');
  const invoiceViews = rootRef.child(`${invoceViewBaseUrl}/${branchId}`);
  const currentInvoiceViewsRef = invoiceViews.child(invoiceViewId);
  currentInvoiceViewsRef.remove();
};
