import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// @mui
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
// components
import TableSubItemsList from '../../components/ui/Table/TableSubItemsList2';
import { resetCart } from '../../redux/slices/product';
import { setCurrentCompany, setCurrentBranch } from '../../redux/slices/company';
import { deleteCompany, getCompaniesByUser, getCompany } from '../../services/CompanyService';
import NewCompanyModal from '../../components/company-selection/NewCompanyModal';
import validateCompanySubscribed from '../../utils/validatePayments';
import validateUserHasPermission from '../../utils/validateUserHasPermission';
import { setCurrentBoard } from '../../redux/slices/kanban';
import getCompanyToRedux from '../../utils/getCompanyToRedux';
import useLocales from '../../hooks/useLocales';
import { getPermissionsOfRoleByUid } from '../../services/RoleService';

const MyOrganizations = () => {
  const history = useHistory();
  const { myProfile, userProfile } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  const { currentBranch, currentCompany } = useSelector((state) => state.company);

  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState({});

  console.log('selectedCompany12711', selectedCompany);
  const [selectedCompanytable, setSelectedCompanytable] = useState({});
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [openBranchModal, setOpenBranchModal] = useState(false);
  // const [selectedItem, setSelectedItem] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [companyTemp, setCompanyTemp] = useState({});
  const [permissions, setPermissions] = useState({});
  const { translate } = useLocales(); // Usamos el hook para traducir

  const handleSelectedBranch = async (branchSelected) => {
    if (currentBranch.id !== branchSelected.id) {
      dispatch(resetCart());
      dispatch(setCurrentBoard({}));

      const companyToRedux = getCompanyToRedux({
        ...companyTemp,
        id: companyTemp.id,
        name: companyTemp.companyName,
      });

      dispatch(setCurrentCompany(companyToRedux));

      dispatch(
        setCurrentBranch({
          id: branchSelected.id,
          name: branchSelected.name,
          photoURLCover: branchSelected.photoURLCover,
          photoURL: branchSelected.photoURL,
          type: branchSelected.type,
        })
      );

      // setSelectedCompany(currentBranch); // borar talvez
      // setSelectedCompany(companySelected);
      enqueueSnackbar(`Sucursal: ${branchSelected.name} ${'Seleccionada'}`, {
        variant: 'success',
      });

      // console.log('companyTemp', companyTemp);
      const validation = await validateCompanySubscribed(companyTemp?.subscription, companyTemp, userProfile);
      if (!validation) {
        history.push(`/dashboard/payment`);
      }
    }
  };

  const TABLE_HEAD = [
    { id: 'companyName', label: translate('welcome7.message'), alignRight: false },
    { id: '', label: '', alignRight: false },
  ];

  const handleSelectedCompany = (companySelected) => {
    setCompanyTemp(companySelected);
    console.log('companySelected', companySelected);
    setSelectedCompany(companySelected);

    setSelectedCompanytable(companySelected.id); // borrar talvez
    /* if (currentCompany.id !== companySelected.id) {
      dispatch(setCurrentBranch(''));
      dispatch(
        setCurrentCompany({
          id: companySelected.id,
          name: companySelected.companyName,
          photoURLCover: companySelected.photoURLCover,
          photoURL: companySelected.photoURL,
        })
      );
      // localStorage.setItem('companyId', companySelected.id);
      setSelectedCompany(companySelected);

      enqueueSnackbar(`Compañía: ${companySelected.companyName} ${'Seleccionada'}`, {
        variant: 'success',
      }); 
    } */
  };

  // eslint-disable-next-line no-unused-vars
  const handleCreateBranch = async (item) => {
    const flag = await validateUserHasPermission(myProfile, companyTemp.id, 'create-branch', permissions);
    if (!flag) {
      enqueueSnackbar(`No tiene permiso para crear sucursales`, {
        variant: 'error',
      });
      return;
    }

    // setSelectedItem(item);
    setOpenBranchModal(true);
  };

  const handleDelete = async (id) => {
    const flag = await validateUserHasPermission(myProfile, id, 'delete-company');
    if (!flag) {
      enqueueSnackbar(`No tiene permiso para eliminar esta compañía`, {
        variant: 'error',
      });
      return;
    }
    const company = await getCompany(id);
    await deleteCompany(company, id);

    if (currentCompany.id === id) {
      dispatch(setCurrentCompany({}));
      dispatch(setCurrentBranch({}));
    }
    const newList = companies.filter((el) => el.id !== id);
    setCompanies(newList);
  };

  // const findCompanyById = (id) => {
  //   return companies.filter((el) => el.id === id)[0];
  // };

  const onBranchCreated = async (_, branch) => {
    const companyToRedux = getCompanyToRedux({
      ...companyTemp,
      id: companyTemp.id,
      name: companyTemp.companyName,
    });

    dispatch(setCurrentCompany(companyToRedux));
    dispatch(setCurrentBranch(branch));
    // agregar validación de pago
    getCompanies();

    const validation = await validateCompanySubscribed(companyTemp?.subscription, companyTemp, userProfile);
    if (!validation) {
      history.push(`/dashboard/payment`);
    }

    /* const companyId = branch.parentCompanyId;
    const myCompany = findCompanyById(companyId); // companies[companyId];
    const branches = [...myCompany.branches,  { name: branch.name }];
    const companyEdited = { ...myCompany, branches };
    const newList = [...companies, { [companyId]: companyEdited }];
    setCompanies(newList); */
  };

  const handleEditItem = (item, id) => {
    if (currentCompany.id !== id) {
      const companyToRedux = getCompanyToRedux({
        ...companyTemp,
        ...item,
        id: companyTemp.id,
        name: companyTemp.companyName,
      });

      dispatch(setCurrentCompany(companyToRedux));
      dispatch(setCurrentBranch({}));
    }

    history.push(`/dashboard/organization/${id}/settings`);
  };

  const getCompanies = useCallback(async () => {
    try {
      const companies = await getCompaniesByUser(myProfile, ['owner', 'aceptado']);
      const companiesToShow = companies.filter((el) => !el.isDisabled);
      setCompanies(companiesToShow);
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myProfile]);

  useEffect(() => {
    const fetchPermissions = async () => {
      const { roleId } = myProfile.companies?.[companyTemp.id];

      await getPermissionsOfRoleByUid(companyTemp.id, roleId).then((data) => {
        setPermissions(data);
      });
    };

    const isUserInCompany = () => myProfile.companies[companyTemp.id]?.role !== undefined;

    if (companyTemp?.id && isUserInCompany) fetchPermissions();
  }, [companyTemp, myProfile]);

  return (
    <>
      <TableSubItemsList
        TABLE_HEAD={TABLE_HEAD}
        getDocuments={getCompanies}
        handleDelete={handleDelete}
        nameInSpanish={translate('welcome5.button')}
        nameInEnglish="organization"
        isMasculine={Boolean(false)}
        needMassiveCharge={Boolean(false)}
        isLoading={isLoading}
        documents={companies}
        applySortFilter={applySortFilter}
        isList="branches"
        handleCreateSubItem={handleCreateBranch}
        handleClickItem={handleSelectedCompany}
        handleClickSubItem={handleSelectedBranch}
        handleEditItem={handleEditItem}
        selectedCompany={selectedCompanytable}
        setSelectedCompany={setSelectedCompanytable}
        dontNeedCheckBox={Boolean(true)}
        needSettings={Boolean(true)}
        searchList={['companyName', 'comercialName']}
      />

      {openBranchModal && (
        <NewCompanyModal
          success={success}
          setSuccess={setSuccess}
          company={{}}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          setOpenModal={setOpenBranchModal}
          openModal={openBranchModal}
          type="branch"
          parentCompany={selectedCompany}
          onBranchCreated={onBranchCreated}
        />
      )}
    </>
  );
};

MyOrganizations.propTypes = {};

export default MyOrganizations;

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => _user.companyName.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
