import PropTypes from 'prop-types';
// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { FormHelperText, Autocomplete, TextField, Chip } from '@mui/material';
//
import Editor from '../editor2';

// ----------------------------------------------------------------------

RHFCombobox.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
};

export default function RHFCombobox({ name, label, ...other }) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <Autocomplete
          onChange={(event, newValue) => field.onChange(newValue)}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => <Chip {...getTagProps({ index })} key={option} size="small" label={option} />)
          }
          renderInput={(params) => <TextField label={label} fullWidth {...params} />}
          {...other}
        />
      )}
    />
  );
}
