import React, { useMemo, useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
  Autocomplete,
  MenuItem,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import firebase from 'firebase/app';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import * as Yup from 'yup';
import algoliasearch from 'algoliasearch/lite';
import { styled } from '@mui/material/styles';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { updateCompany, createNewCompany, setCurrentCompany, setCurrentBranch } from '../../redux/slices/company';
import { UploadAvatar } from '../../components/upload';
import validateUserHasPermission from '../../utils/validateUserHasPermission';
import getCompanyToRedux from '../../utils/getCompanyToRedux';
import { currencyList } from '../../constants/labels';
import { ID_TYPE } from '../../constants/hacienda/IDType';
import { countries } from '../../constants/selects';
import { PATH_DASHBOARD } from '../../routes/paths';
import useLocales from '../../hooks/useLocales';
import Editor from '../../components/editor2';
// import { RHFEditor } from '../../components/hook-form';
import Markdown from '../../components/Markdown';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

const client = algoliasearch(process.env.REACT_APP_APPLICATION_ID, process.env.REACT_APP_SEARCH_API_KEY);
const index = client.initIndex('dev_territoryCR');

const OrganizationForm = ({
  setSuccess,
  success,
  company,
  setIsEdit,
  isEdit,
  permissions,
  accountNames,
  setAccountNames,
}) => {
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const user = useMemo(() => firebase.auth().currentUser, []);
  const { myProfile, userProfile } = useSelector((state) => state.user);
  const [results, setResults] = useState([]);
  const { translate } = useLocales();
  const [isPersonal, setIsPersonal] = useState(false);

  const isDuplicate = !!company.id;
  const isEditOrDuplicate = isEdit || isDuplicate;

  // const [isNoClicked, setIsNoClicked] = useState(true);
  const [isDisabled, setIsDisabled] = useState(isEdit);

  const companySchema = {
    companyName: Yup.string().required('Company Name is required'),
    comercialName: Yup.string().required('Comercial Name is required'),
    email: Yup.string().email().required('E-Mail is required'),
    phone: Yup.number().required('Number is required'),
    companyType: Yup.string().required('Company Type is required'),
    country: Yup.string().required('Country is required'),
    type: Yup.string().required('Type is required'),
    firstName: isPersonal && Yup.string().required('First Name is required'),
    lastName: isPersonal && Yup.string().required('Last Name is required'),
  };
  const urlImageDefaultCompany = '/static/company/avatar.png';
  const urlImageDefaultCompanyCover = '/static/company/company.jpg';

  const getCompanyName = () => {
    if (isEdit) {
      return company.companyName || '';
    }
    if (isDuplicate) {
      return `Copy ${company.companyName}` || '';
    }
    return '';
  };

  const companyInitialValues = {
    companyName: getCompanyName(),
    comercialName: isEditOrDuplicate ? company.comercialName || '' : '',
    idType: isEditOrDuplicate ? company.idType || '' : '',
    idNumber: isEditOrDuplicate ? company.idNumber || '' : '',
    phone: isEditOrDuplicate ? company.phone || '' : '',
    email: isEditOrDuplicate ? company.email || '' : '',
    companyType: isEditOrDuplicate ? company.companyType || 'company' : 'company',
    photoURL: isEditOrDuplicate ? company.photoURL || '' : urlImageDefaultCompany,
    photoURLCover: isEditOrDuplicate ? company.photoURL || '' : urlImageDefaultCompanyCover,
    authorizedUsersIds: company.authorizedUsersIds || [myProfile?.uid || userProfile.uid],
    rootUser: company.rootUser || myProfile.uid || userProfile.uid,
    log: isEditOrDuplicate ? company.log || [] : [],
    menu: isEditOrDuplicate ? company.menu || false : true,
    firstName: isEditOrDuplicate ? company.firstName || '' : '',
    lastName: isEditOrDuplicate ? company.lastName || '' : '',
    accountNames: isEditOrDuplicate ? company.accountNames || '' : '',
    country: company.country || 'cr',
    currency: company.currency || 'USD',
    type: company.type || 'Legal',
    codeProvincia: isEditOrDuplicate ? company.codeProvincia || '' : '',
    codeDistrito: isEditOrDuplicate ? company.codeDistrito || '' : '',
    codeCanton: isEditOrDuplicate ? company.codeCanton || '' : '',
    codeBarrio: isEditOrDuplicate ? company.codeBarrio || '' : '',
    nameProvincia: isEditOrDuplicate ? company.nameProvincia || '' : '',
    nameBarrio: isEditOrDuplicate ? company.nameBarrio || '' : '',
    addressLocation: isEditOrDuplicate ? company.addressLocation || '' : '',
    direction: isEditOrDuplicate ? company.direction || '' : '',
  };

  useEffect(() => {
    if (company) {
      setResults([
        {
          nameProvincia: company?.nameProvincia || '',
          nameBarrio: company?.nameBarrio || '',
          nameDistrito: company?.nameDistrito || '',
          nameCanton: company?.nameCanton || '',
          codeProvincia: company?.codeProvincia || '',
          codeDistrito: company?.codeDistrito || '',
          codeCanton: company?.codeCanton || '',
          codeBarrio: company?.codeBarrio || '',
          label: company?.nameBarrio || '',
          title: company?.nameBarrio || '',
        },
      ]);

      // console.log(company);
    }
  }, [company]);

  const performSearch = async (value) => {
    const { hits } = await index.search(value, {
      hitsPerPage: 10,
    });

    const results = hits.map((hit) => {
      const {
        objectID: key,
        nameProvincia,
        nameBarrio,
        nameDistrito,
        nameCanton,
        codeProvincia,
        codeDistrito,
        codeCanton,
        codeBarrio,
        nameBarrio: label,
        _highlightResult,
      } = hit;
      // console.log('hit', hit);
      const {
        nameBarrio: { value: _nameBarrio }, // title
      } = _highlightResult;

      const title = [_nameBarrio, nameDistrito, nameCanton, nameProvincia].join('/');

      return {
        key,
        nameProvincia,
        nameBarrio,
        nameDistrito,
        nameCanton,
        codeProvincia,
        codeDistrito,
        codeCanton,
        codeBarrio,
        label,
        title,
      };
    });

    // console.log(results);

    setResults(results);
  };

  // const handleChange = (e) => {
  //   const { value } = e?.target || values.descriptionPossessionService;

  //   return value && value.length >= 3 ? performSearch(value) : setResults([]);
  // };

  const handleChange = (e) => {
    const { value } = e?.target || '';

    // eslint-disable-next-line no-unused-expressions
    value === '' ? setResults([]) : performSearch(value);
  };

  const createSchema = Yup.object().shape(companySchema);

  // const changeNameCompanySelected = (id) => {
  //   if (id === company.id) dispatch(setCurrentCompany({ id, name: values.companyName }));
  // };

  const callUpdateCompany = async () => {
    const flag = await validateUserHasPermission(myProfile, company.id, 'edit-company', permissions);
    if (!flag) {
      enqueueSnackbar(`No tiene permiso para editar esta compañía`, {
        variant: 'error',
      });
    } else {
      // console.log('editar');
      const updateFunction = updateCompany;
      await dispatch(updateFunction({ ...values, id: company.id }, user.uid));
      // changeNameCompanySelected(company.id);
    }
    return flag;
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: companyInitialValues,
    validationSchema: createSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        if (isEdit) {
          if (isDisabled) {
            setIsDisabled(false);
          } else {
            const updateFunction = updateCompany;
            const newCompany = {
              ...values,
              accountNames,
              id: company.id,
              subscription: company?.subscription,
              rootUser: company?.rootUser,
            };
            dispatch(updateFunction(newCompany, user.uid));

            const companyToRedux = getCompanyToRedux({
              ...company,
              ...values,
              accountNames,
              id: company.id,
              name: values.companyName,
            });
            dispatch(setCurrentCompany(companyToRedux));
          }
        } else {
          const createFunction = createNewCompany;
          const companyId = await dispatch(
            createFunction({
              ...values,
              accountNames,
              datecreation: new Date().toISOString(), // Agrega la fecha de creación aquí
            })
          );

          const companyToRedux = getCompanyToRedux({
            ...company,
            ...values,
            accountNames,
            id: companyId,
            name: values.companyName,
            datecreation: new Date().toISOString(), // Asegúrate de incluir la fecha en el objeto también aquí
          });
          dispatch(setCurrentCompany(companyToRedux));
          dispatch(setCurrentBranch({}));
        }
        if (!isDisabled) {
          setSuccess(!success);
          enqueueSnackbar(`Company : ${values.companyName} was ${isEdit ? 'Updated' : 'Created'} successfully`, {
            variant: 'success',
          });
          if (isMountedRef.current) {
            setSubmitting(false);
          }
        }
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.code });
          setSubmitting(false);
        }
      }
      if (!isDisabled) {
        handleClose();
      }
    },
  });

  const handleClose = () => {
    setIsEdit(false);
    history.push(PATH_DASHBOARD.user.profile);
  };

  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  const getCountry = (myCode) => {
    let label = '';
    // eslint-disable-next-line consistent-return
    countries.forEach((el) => {
      if (el.code === myCode) {
        // eslint-disable-next-line prefer-destructuring
        label = el.label;
        return label;
      }
    });
    return label;
  };

  const getCodeFromValue = (jsons, value, attr, attr2) => {
    let code = '';
    jsons.forEach((el) => {
      if (el[attr] === value) {
        // eslint-disable-next-line prefer-destructuring
        code = el[attr2];
      }
    });
    return code;
  };

  const renderDirection = () => {
    if (company.country === 'cr') {
      if (company?.addressLocation) {
        return `${company?.addressLocation?.nameBarrio}, ${company?.addressLocation?.nameDistrito}, ${company?.addressLocation?.nameProvincia}`;
      }
      return '';
    }
    return company?.direction || '';
  };

  // eslint-disable-next-line arrow-body-style
  const renderDetailForm = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom component="div">
            {translate('nombre.button')} <strong>{company.companyName}</strong>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom component="div">
            {translate('Table.button48')} <strong>{company.comercialName}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom component="div">
            {translate('Table.button49')} <strong>{getCodeFromValue(ID_TYPE, company.idType, 'code', 'name')}</strong>
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom component="div">
            {translate('Table.button50')} <strong>{company.idNumber}</strong>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom component="div">
            {translate('Table.button51')} <strong>{company.phone}</strong>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom component="div">
            {translate('register.email')} <strong>{company.email}</strong>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom component="div">
            {translate('register.country')} <strong>{getCountry(company.country)}</strong>
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Typography variant="body1" gutterBottom component="div">
            {translate('Table.button52')} <strong>{company.currency}</strong>
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom component="div">
            {company.country === 'cr' ? translate('direct.message') : translate('direct.message')}
            <strong>{renderDirection()}</strong>
          </Typography>
        </Grid>

        {values.companyType === 'personal' && (
          <>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" gutterBottom component="div">
                {translate('register.firtsName')} <strong>{company.firstName}</strong>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body1" gutterBottom component="div">
                {translate('register.lastName')} <strong>{company.lastName}</strong>
              </Typography>
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom component="div">
            {translate('Table.button53')}
            {/* <strong>{company.accountNames}</strong> */}
          </Typography>
          <div style={{ marginLeft: '40px' }}>
            <Markdown children={company.accountNames} />
          </div>
        </Grid>
      </Grid>
    );
  };

  // eslint-disable-next-line arrow-body-style
  const renderFormEdit = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            error={Boolean(touched.companyName && errors.companyName)}
            helperText={touched.companyName && errors.companyName}
            label={translate('Table.button54')}
            {...getFieldProps('companyName')}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={translate('Table.button55')}
            error={Boolean(touched.comercialName && errors.comercialName)}
            helperText={touched.comercialName && errors.comercialName}
            {...getFieldProps('comercialName')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid item>
            <TextField
              select
              error={errors.termsSale}
              fullWidth
              label={translate('Table.button56')}
              {...getFieldProps('idType')}
              // onChange={handleChangeToTermsSale}
            >
              <MenuItem key={'01'} value={'01'}>
                {translate('Table.button57')}
              </MenuItem>
              <MenuItem key={'02'} value={'02'}>
                {translate('Table.button58')}
              </MenuItem>
              <MenuItem key={'03'} value={'03'}>
                {translate('Table.button59')}
              </MenuItem>
              <MenuItem key={'04'} value={'04'}>
                {translate('Table.button60')}
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={translate('Table.button50')}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
            {...getFieldProps('idNumber')}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={translate('Table.button51')}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
            {...getFieldProps('phone')}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            label={translate('register.email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
            {...getFieldProps('email')}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            label={translate('register.country')}
            placeholder={translate('register.country')}
            {...getFieldProps('country')}
            SelectProps={{ native: true }}
            error={Boolean(touched.language && errors.language)}
            helperText={touched.language && errors.language}
          >
            {countries.map((option) => (
              <option key={option.code} value={option.code}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextField
            select
            fullWidth
            label={translate('Table.button52')}
            placeholder="Moneda"
            {...getFieldProps('currency')}
            SelectProps={{ native: true }}
            error={Boolean(touched.language && errors.language)}
            helperText={touched.language && errors.language}
          >
            {currencyList.map((option) => (
              <option key={option.code} value={option.code}>
                {option.label}
              </option>
            ))}
          </TextField>
        </Grid>

        {values.country === 'cr' ? (
          <Grid item xs={12} sm={12}>
            {/* <Autocomplete
              style={{ paddingRight: '0px' }}
              options={results}
              value={{
                nameBarrio: results.find((option) => option.codeBarrio === values.codeBarrio)?.nameBarrio || '',
              }}
              getOptionSelected={(option, value) => value ?? option.codeBarrio === value.codeBarrio}
              getOptionLabel={(option) => option.nameBarrio}
              onChange={(e, value) => setFieldValue('codeBarrio', value?.codeBarrio || '')}
              sx={{ pr: 1 }}
              renderInput={(params) => <TextField sx={{ my: 3 }} {...params} label="Rol" variant="outlined" />}
            /> */}

            {/* <Autocomplete
              onChange={(_event, newValue) => {
                setFieldValue('nameBarrio', newValue?.nameBarrio);
                setFieldValue('codeProvincia', newValue?.codeProvincia);
                setFieldValue('codeDistrito', newValue?.codeDistrito);
                setFieldValue('codeCanton', newValue?.codeCanton);
                setFieldValue('codeBarrio', newValue?.codeBarrio);
              }}
              onInputChange={(event) => handleChange(event)}
              getOptionSelected={(option, value) => value ?? option.codeBarrio === value.codeBarrio}
              getOptionLabel={(option) =>
                `${option.label} ${option.nameCanton} ${option.nameDistrito} ${option.nameProvincia}`
              }
              renderOption={(props, option) => (
                <li
                  {...props}
                  key={option.key}
                  dangerouslySetInnerHTML={{
                    __html: `${option.title} ${option.nameCanton}  ${option.nameDistrito}    ${option.nameProvincia}  `,
                  }}
                />
              )}
              options={results}
              renderInput={(params) => <TextField label={'Ubicación'} fullWidth {...params} />}
            /> */}
            <Autocomplete
              fullWidth
              options={results}
              {...getFieldProps(`addressLocation`)}
              // getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(option) =>
                `${option.label} ${option.nameCanton} ${option.nameDistrito} ${option.nameProvincia}`
              }
              onChange={(event, value) => {
                // console.log(value);
                setFieldValue(`addressLocation`, value);
              }}
              renderOption={(props, option) => (
                <li {...props} key={option.key} dangerouslySetInnerHTML={{ __html: option.title }} />
              )}
              onInputChange={(event) => handleChange(event)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label={translate('direct.message')}
                  placeholder={translate('Table.button61')}
                  name="location"
                  variant="outlined"
                />
              )}
            />
          </Grid>
        ) : (
          <Grid item xs={12}>
            <TextField
              fullWidth
              label={`${translate('direct.message')} ${getCodeFromValue(countries, values.country, 'code', 'label')}`}
              error={Boolean(touched.phone && errors.phone)}
              helperText={touched.phone && errors.phone}
              {...getFieldProps('direction')}
            />
          </Grid>
        )}

        {values.companyType === 'personal' && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={translate('register.firtsName')}
                error={Boolean(touched.firstName && errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                {...getFieldProps('firstName')}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label={translate('register.lastName')}
                error={Boolean(touched.lastName && errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                {...getFieldProps('lastName')}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12}>
          {/* <TextField
            fullWidth
            label="Números de cuenta"
            // error={Boolean(touched.email && errors.email)}
            // helperText={touched.email && errors.email}
            {...getFieldProps('accountNames')}
          /> */}

          <LabelStyle>{translate('Table.button53')}</LabelStyle>
          <Editor
            simple
            name="accountNames"
            value={accountNames}
            onChange={(e) => {
              setAccountNames(e);
            }}
            placeholder={translate('Table.button62')}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card>
                <Box
                  sx={{
                    my: 2,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <UploadAvatar value={values.photoURL} onChange={(value) => setFieldValue('photoURL', value)} />
                  <>
                    <Typography>{translate('Table.button63')}</Typography>
                    <FormControlLabel
                      control={
                        <Switch
                          {...getFieldProps('companyType')}
                          checked={values.companyType === 'personal'}
                          onChange={() => {
                            const newValue = values.companyType === 'company' ? 'personal' : 'company';

                            setFieldValue('companyType', newValue);
                            setIsPersonal(!isPersonal);
                          }}
                          color="primary"
                        />
                      }
                      labelPlacement="bottom"
                      label={`${translate('Table.button63')} | Personal`}
                    />
                  </>
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              <Card sx={{ pb: 6 }}>
                <CardContent>
                  {isDisabled ? renderDetailForm() : renderFormEdit()}

                  <Box
                    sx={{
                      mt: 3,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    <LoadingButton type="submit" variant="contained" pending={isSubmitting}>
                      {isDisabled ? 'Editar' : 'Save Changes'}
                    </LoadingButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Grid>
  );
};

OrganizationForm.propTypes = {
  success: PropTypes.bool,
  setSuccess: PropTypes.func,
  company: PropTypes.object,
  setIsEdit: PropTypes.func,
  isEdit: PropTypes.bool,
  permissions: PropTypes.object,
  accountNames: PropTypes.string,
  setAccountNames: PropTypes.func,
};

export default OrganizationForm;
