import { getPermissionsOfRoleByUid } from '../services/RoleService';

const userIsOwner = (user, companyId) => user.companies[companyId]?.role === 'owner';

const userHasPermissions = async (user, companyId, permission, permissions) => {
  const roleId = user.companies[companyId]?.roleId;
  let permissionsTemp = permissions;
  if (!permissionsTemp) {
    permissionsTemp = await getPermissionsOfRoleByUid(companyId, roleId);
  }
  //   console.log('permissions', permissions);
  //   console.log('permission', permissions[permission]);
  return !!permissionsTemp?.[permission];
};

export default async function validateUserHasPermission(user, companyId, permission, permissions = {}) {
  //   console.log('user', user);
  if (userIsOwner(user, companyId)) {
    return true;
  }
  return userHasPermissions(user, companyId, permission, permissions);
}
