import React from 'react';

import PropTypes from 'prop-types';

// material
import { Box, Modal, Typography, Button } from '@material-ui/core';

const ConfirmationModal = ({
  openModal,
  setOpenModal,
  onConfirm,
  title,
  subtitle
}) => {
  const handleClose = () => {
    setOpenModal(false);
  };

  return (
    <Modal
      open={openModal}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pl: 20,
        pr: 20
      }}
      BackdropProps={{
        transitionDuration: 2000
      }}
    >
      <Box
        sx={{
          background: 'white',
          width: '40%',
          position: 'absolute',
          p: 3
        }}
      >
        <Typography gutterBottom variant="h4">
          {title}
        </Typography>
        <Typography variant="subtitle2">{subtitle}</Typography>

        <Box sx={{ mt: 3 }}>
          <Button variant="contained" onClick={onConfirm} sx={{ mr: 2 }}>
            Confirm
          </Button>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

ConfirmationModal.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string
};

export default ConfirmationModal;
