import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCompany } from '../../../../services/CompanyService';
import CompanyForm from '../../../../components/administration/company/CompanyForm';

function CompanyEdit() {
  const { currentCompany } = useSelector((state) => state.company);
  const [company, setCompany] = useState({});

  useEffect(() => {
    const fetchCompany = async () => {
      await getCompany(currentCompany.id).then((data) => {
        setCompany(data);
      });
    };

    fetchCompany();
  }, [currentCompany]);

  return <CompanyForm company={company} isEdit />;
}

CompanyEdit.propTypes = {};

export default CompanyEdit;
