import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Switch,
  TextField,
  Typography,
  Divider,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import firebase from 'firebase/app';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import { updateCompany } from '../../../redux/slices/company';
import { UploadAvatar } from '../../upload';

const coins = [
  { code: 'USD', label: 'Dollar' },
  { code: 'CRC', label: 'Colón' },
  { code: 'MXN', label: 'Peso Mexicano' },
  { code: 'QTZ', label: 'Quetzal' },
];
const languages = [
  { code: 'es', label: 'Spanish' },
  { code: 'en', label: 'English' },
];

const countries = [
  { code: 'mx', label: 'México' },
  { code: 'cr', label: 'Costa Rica' },
  { code: 'gt', label: 'Guatemala' },
];

const CompanyForm = ({ company }) => {
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const user = useMemo(() => firebase.auth().currentUser, []);
  const [isPersonal, setIsPersonal] = useState(false);
  const companySchema = {
    companyName: Yup.string().required('Company Name is required'),
    comercialName: Yup.string().required('Comercial Name is required'),
    email: Yup.string().email().required('E-Mail is required'),
    phone: Yup.number().required('Number is required'),
    companyType: Yup.string().required('Company Type is required'),
    firstName: isPersonal && Yup.string().required('First Name is required'),
    lastName: isPersonal && Yup.string().required('Last Name is required'),
  };
  const [isEdit, setIsEdit] = useState(false);
  const urlImageDefaultCompany = '/static/company/avatar.png';
  const urlImageDefaultCompanyCover = '/static/company/company.jpg';

  const companyInitialValues = {
    companyName: company.companyName || '',
    comercialName: company.comercialName || '',
    phone: company.phone || '',
    email: company.email || '',
    companyType: company.companyType || 'company',
    photoURL: company.photoURL || urlImageDefaultCompany,
    photoURLCover: company.photoURL || urlImageDefaultCompanyCover,
    authorizedUsersIds: company.authorizedUsersIds || [user.uid],
    rootUser: company.rootUser || user.uid,
    log: company.log || [],
    menu: company.menu || false,
    branches: company.branches || [],

    contactEmail: company.contactEmail || '',
    sector: company.sector || '',
    country: company.country || '',
    address: company.address || '',
    zipCode: company.zipCode || '',
    numLegal: company.numLegal || '',
    numTax: company.numTax || '',
    webSite: company.webSite || '',
    fax: company.fax || '',
    currency: company.currency || '',
    language: company.language || '',
  };

  const createSchema = Yup.object().shape(companySchema);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: companyInitialValues,
    validationSchema: createSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        await dispatch(updateCompany({ ...values, id: company.id }));

        enqueueSnackbar(`Company : ${values.companyName} was ${'Updated'} successfully`, {
          variant: 'success',
        });
        setIsEdit(false);
        if (isMountedRef.current) {
          setSubmitting(false);
        }
      } catch (error) {
        console.log(error);
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.code });
          setSubmitting(false);
        }
      }
    },
  });

  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <Grid>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card>
                <Box
                  sx={{
                    my: 2,
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <UploadAvatar
                    disabled={!isEdit}
                    value={values.photoURL}
                    onChange={(value) => setFieldValue('photoURL', value)}
                  />
                  <Typography>Company type</Typography>
                  <FormControlLabel
                    disabled={!isEdit}
                    control={
                      <Switch
                        {...getFieldProps('companyType')}
                        checked={values.companyType === 'personal'}
                        onChange={() => {
                          const newValue = values.companyType === 'company' ? 'personal' : 'company';

                          setFieldValue('companyType', newValue);
                          setIsPersonal(!isPersonal);
                        }}
                        color="primary"
                      />
                    }
                    labelPlacement="bottom"
                    label="Company"
                  />
                </Box>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              <Card sx={{ pb: 6 }}>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={!isEdit}
                        fullWidth
                        error={Boolean(touched.companyName && errors.companyName)}
                        helperText={touched.companyName && errors.companyName}
                        label="Name"
                        {...getFieldProps('companyName')}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={!isEdit}
                        fullWidth
                        label={'Comercial Name'}
                        error={Boolean(touched.comercialName && errors.comercialName)}
                        helperText={touched.comercialName && errors.comercialName}
                        {...getFieldProps('comercialName')}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={!isEdit}
                        fullWidth
                        label="Phone Number"
                        error={Boolean(touched.phone && errors.phone)}
                        helperText={touched.phone && errors.phone}
                        {...getFieldProps('phone')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={!isEdit}
                        fullWidth
                        label="E-Mail"
                        error={Boolean(touched.email && errors.email)}
                        helperText={touched.email && errors.email}
                        {...getFieldProps('email')}
                      />
                    </Grid>

                    <Divider />
                    {/* extra fields */}
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={!isEdit}
                        fullWidth
                        label="Principal Contact Email"
                        error={Boolean(touched.contactEmail && errors.contactEmail)}
                        helperText={touched.contactEmail && errors.contactEmail}
                        {...getFieldProps('contactEmail')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={!isEdit}
                        fullWidth
                        label="Sector"
                        error={Boolean(touched.sector && errors.sector)}
                        helperText={touched.sector && errors.sector}
                        {...getFieldProps('sector')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={!isEdit}
                        select
                        fullWidth
                        label="País"
                        placeholder="País"
                        {...getFieldProps('country')}
                        SelectProps={{ native: true }}
                        error={Boolean(touched.language && errors.language)}
                        helperText={touched.language && errors.language}
                      >
                        <option value="" />
                        {countries.map((option) => (
                          <option key={option.code} value={option.code}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={!isEdit}
                        fullWidth
                        label="Address"
                        error={Boolean(touched.address && errors.address)}
                        helperText={touched.address && errors.address}
                        {...getFieldProps('address')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={!isEdit}
                        fullWidth
                        label="ZipCode"
                        error={Boolean(touched.zipCode && errors.zipCode)}
                        helperText={touched.zipCode && errors.zipCode}
                        {...getFieldProps('zipCode')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={!isEdit}
                        fullWidth
                        label="Cédula Jurídica"
                        error={Boolean(touched.numLegal && errors.numLegal)}
                        helperText={touched.numLegal && errors.numLegal}
                        {...getFieldProps('numLegal')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={!isEdit}
                        fullWidth
                        label="Tax Identification"
                        error={Boolean(touched.numTax && errors.numTax)}
                        helperText={touched.numTax && errors.numTax}
                        {...getFieldProps('numTax')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={!isEdit}
                        fullWidth
                        label="Web Site"
                        error={Boolean(touched.webSite && errors.webSite)}
                        helperText={touched.webSite && errors.webSite}
                        {...getFieldProps('webSite')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={!isEdit}
                        fullWidth
                        label="Fax"
                        error={Boolean(touched.fax && errors.fax)}
                        helperText={touched.fax && errors.fax}
                        {...getFieldProps('fax')}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={!isEdit}
                        select
                        fullWidth
                        label="Currency"
                        placeholder="Currency"
                        {...getFieldProps('currency')}
                        SelectProps={{ native: true }}
                        error={Boolean(touched.currency && errors.currency)}
                        helperText={touched.currency && errors.currency}
                      >
                        <option value="" />
                        {coins.map((option) => (
                          <option key={option.code} value={option.code}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        disabled={!isEdit}
                        select
                        fullWidth
                        label="Language"
                        placeholder="Language"
                        {...getFieldProps('language')}
                        SelectProps={{ native: true }}
                        error={Boolean(touched.language && errors.language)}
                        helperText={touched.language && errors.language}
                      >
                        <option value="" />
                        {languages.map((option) => (
                          <option key={option.code} value={option.code}>
                            {option.label}
                          </option>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>

                  <Box
                    sx={{
                      mt: 3,
                      display: 'flex',
                      justifyContent: 'flex-end',
                    }}
                  >
                    {!isEdit && (
                      <LoadingButton type="submit" variant="contained" onClick={() => setIsEdit(true)}>
                        Edit Company
                      </LoadingButton>
                    )}
                    {isEdit && (
                      <LoadingButton type="submit" variant="contained" pending={isSubmitting}>
                        Save Changes
                      </LoadingButton>
                    )}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Grid>
  );
};

CompanyForm.propTypes = {
  company: PropTypes.object,
  isEdit: PropTypes.bool,
};

export default CompanyForm;
