export const DELETE_CLIENT = {
  title: 'Are you sure ?',
  message: 'This client will be marked as inactive',
};

export const DELETE_ARTICLE = {
  title: '¿Estás seguro de que desea eliminar?',
  message: 'Este artículo se marcará como inactivo y se eliminará de la lista.',
};

export const DELETE_INVOICE = {
  title: 'Are you sure ?',
  message: 'This invoice will be marked as inactive and removed from list',
};

export const DELETE_PURCHASE_ORDER = {
  title: 'Are you sure ?',
  message: 'This purchase order will be marked as inactive and removed from list',
};

export const CONVERT_PURCHASE_ORDER = {
  title: 'Are you sure ?',
  message: 'This purchase order will converted to invoice and removed from current list',
};

export const DELETE_SUPPLIER_INVOICE = {
  title: 'Are you sure ?',
  message: 'This invoice will be marked as inactive and removed from list',
};

export const DELETE_EXPENSE = {
  title: 'Are you sure ?',
  message: 'This expense will be marked as inactive and removed from list',
};

export const DELETE_PROCESS = {
  title: 'Are you sure ?',
  message: 'This process will be marked as inactive and removed from list',
};

export const GO_TO_CONTACT = {
  title: 'Deseas ir a cargar contacto ?',
};

export const DELETE = (nameInSpanish, isMasculine) => {
  let message = `Esta ${nameInSpanish} se marcará como inactiva y se eliminará de la lista.`;
  if (isMasculine) {
    message = `Este ${nameInSpanish} se marcará como inactivo y se eliminará de la lista.`;
  }
  return {
    title: '¿Estás seguro de que desea eliminar?',
    message,
  };
};

export const ADD = (nameInSpanish, isMasculine) => {
  let message = `Esta ${nameInSpanish} se agregará de la lista.`;
  if (isMasculine) {
    message = `Este ${nameInSpanish} se agregará de la lista.`;
  }
  return {
    title: '¿Estás seguro de que desea agregar?',
    message,
  };
};

export const EDIT = (nameInSpanish, isMasculine) => {
  let message = `Esta ${nameInSpanish} se agregará de la lista.`;
  if (isMasculine) {
    message = `Este ${nameInSpanish} se editará.`;
  }
  return {
    title: '¿Estás seguro de que desea editar?',
    message,
  };
};

export const currencyList = [
  { code: 'USD', label: 'USD', symbol: '$' },
  { code: 'EUR', label: 'EUR', symbol: '€' },
  { code: 'CRC', label: 'CRC', symbol: '₡' },
  { code: 'MXN', label: 'MXN', symbol: '$' },
  // { code: 'AR$', label: 'AR$' },
  { code: 'GTQ', label: 'GTQ', symbol: 'Q' }, // QTZ
];
