import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { cancelPayPalSubscription } from '../../../services/SubscriptionService';
import { setCurrentCompany } from '../../../redux/slices/company';
import { dispatch } from '../../../redux/store';

const CancelSubscriptionDialog = ({ open, handleClose, company, subscription }) => {
  const companyName = company.name;
  const [name, setName] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  // const updateFirebaseCompany = (company, subscription) => {
  //   dispatch(
  //     updateCompany({
  //       ...company,
  //       subscription: {
  //         ...subscription,
  //         renewal: false,
  //         renewalCancelledAt: new Date().toString(),
  //       },
  //     })
  //   );
  // };

  const modifyCompanyInRedux = (company, subscription) => {
    dispatch(
      setCurrentCompany({
        ...company,
        subscription: {
          ...subscription,
          renewal: false,
          renewalCancelledAt: new Date().toString(),
        },
      })
    );
  };

  const callCancelSubScription = async () => {
    const reason = 'Suscripcion cancelada desde la página de QOLOLO';
    const result = await cancelPayPalSubscription(company.subscription.payPalSubscriptionId, reason);

    console.log('result', result);

    const goodStatus = result.statusCode >= 200 && result.statusCode < 300;
    if (!goodStatus) {
      console.error('error', result);
      enqueueSnackbar(`No se ha podido cancelar la suscripción. Error ${result.name}`, {
        variant: 'error',
      });
      return;
    }
    console.log('result', result);

    enqueueSnackbar(`Suscripción cancelada exitosamente`, {
      variant: 'success',
    });

    // updateFirebaseCompany(company, subscription);
    modifyCompanyInRedux(company, subscription);

    handleClose();
  };

  const validateField = () => {
    if (companyName !== '' && companyName === name) {
      callCancelSubScription();
    } else {
      enqueueSnackbar(`El nombre de la compañía no coincide`, {
        variant: 'error',
      });
    }
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Cancelar Suscripción</DialogTitle>
      <DialogContent>
        <DialogContentText>¿Está seguro de que desea eliminar la subscipción de esta compañía?</DialogContentText>
        <DialogContentText>
          Para confirmar ingrese el nombre de la compañía <strong>{companyName}</strong>
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Nombre de la compañía"
          type="text"
          text={name}
          onChange={(e) => setName(e.target.value)}
          fullWidth
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancelar</Button>
        <Button onClick={validateField}>Confirmar</Button>
      </DialogActions>
    </Dialog>
  );
};

CancelSubscriptionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  subscription: PropTypes.object,
};

export default CancelSubscriptionDialog;
