import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { createLog } from '../redux/slices/log';
import { getCompanyById } from './CompanyService';

export async function getBranchesByCompany(companyId) {
  try {
    const rootRef = firebase.database().ref(`/companies/${companyId}/branches`);
    const snapshot = await rootRef.once('value');
    return snapshot.val() || {};
  } catch (error) {
    console.error('Error fetching branches:', error);
    throw error;
  }
}

export const getBranchesListByCompany = async (company) => {
  const branches = [];
  const branchesIds = Object.keys(company?.branches || '');

  await Promise.all(
    branchesIds.map(async (id) => {
      await firebase
        .database()
        .ref(`branches/${id}`)
        .once('value')
        .then((snap) => {
          const snapData = snap.val();
          const branch = {
            ...snapData,
            id: snap.key,
          };
          if (branch.name) {
            branches.push(branch);
          }
        });
    })
  );
  return branches;
};

export function getBranch(branchId) {
  return firebase
    .database()
    .ref()
    .child(`branches/${branchId}`)
    .get()
    .then((snapshot) => (snapshot.exists() ? { id: snapshot.key, ...snapshot.val() } : []))
    .catch((error) => {
      console.error(error);
    });
}

export const deleteBranch = async (branch, id) => {
  const rootRef = firebase.database().ref('/');
  const branches = rootRef.child('branches');
  const companies = rootRef.child('companies');
  const parentCompanyRef = companies.child(branch.parentCompanyId);
  const parentCompany = await getCompanyById(branch.parentCompanyId);

  // delete the this branch from parent company

  delete parentCompany.branches[id];

  // const nonRemovedBranches = parentCompany.branches.filter((item) => item !== id);

  const updatedParentCompany = {
    ...parentCompany,
    branches: parentCompany.branches,
  };
  await parentCompanyRef.update(updatedParentCompany);

  // delete branch
  const currentBranchRef = branches.child(id);
  currentBranchRef.remove();
};
