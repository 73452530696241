import { createSlice } from '@reduxjs/toolkit';
import firebase from 'firebase/app';
// import { useSelector } from 'react-redux';
import getFieldsModified from '../../utils/getFieldsModified';
import getFieldsModifiedContact from '../../utils/getFieldsModifiedContact';
import { getUserByUid } from '../../services/UserService';

const initialState = {
  logs: [],
};
const slice = createSlice({
  name: 'log',
  initialState,
  reducers: {
    getLogsSuccess(state, action) {
      state.logs = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
// export const {} = slice.actions;

export function getLogs() {
  // eslint-disable-next-line no-unused-vars
  return async (dispatch) => {
    try {
      await firebase
        .database()
        .ref.child('/logs')
        .orderByKey('modelId')
        .equalTo('-MuHfUnEDw1XQWZ27xw9')
        .once('value')
        .then((snapshot) => {
          snapshot.forEach((log) => {
            console.log(log);
          });
        });
      // dispatch(slice.actions.getLogsSuccess(response.data.profile));
    } catch (error) {
      console.error(error);
      // dispatch(slice.actions.hasError(error));
    }
  };
}

export const getLogsByModel = async (id) => {
  const logsRef = firebase.database().ref(`/logs`);
  const logs = await logsRef
    .child(id)
    .once('value')
    .then((snap) => snap.val());
  console.log('los logs', logs, 'los id', id);

  return logs;
  // return logs.sort((a, b) => new Date(b.date) - new Date(a.date));
};

export const createLog = async (model, action, key, oldValue = [], updatedValue = [], isSuperAdmin = false) => {
  let userObject = {};
  if (isSuperAdmin) {
    userObject = { modifierUserName: 'Qololo Admin', modifierUserUid: 'Qololo_Admin' };
  } else {
    const user = firebase.auth().currentUser;
    const currentUser = await getUserByUid(user.uid);
    userObject = {
      modifierUserName: `${currentUser?.firstName} ${currentUser?.lastName}` || user.email,
      modifierUserUid: user.uid,
    };
  }

  const fields = getFieldsModified(oldValue, updatedValue);
  const newLog = {
    ...{
      ...userObject,
      date: new Date().toISOString(),
      model,
      action,
      fieldsModified: fields,
    },
  };

  // console.log(newLog)

  try {
    const rootRef = firebase.database().ref(`/logs/${key}`);
    const log = await rootRef.push(newLog);
    return log.key;
  } catch (err) {
    console.error(err);
    return false;
  }
};

export const createLogContacts = async (model, action, key, oldValue = [], updatedValue = []) => {
  console.log('model', model);
  console.log('action', action);
  console.log('key', key);
  console.log('oldValue', oldValue);
  console.log('updatedValue', updatedValue);
  const user = firebase.auth().currentUser;
  const currentUser = await getUserByUid(user.uid);

  const fields = getFieldsModifiedContact(oldValue, updatedValue);
  const newLog = {
    ...{
      modifierUserName: `${currentUser?.firstName} ${currentUser?.lastName}` || user.email,
      modifierUserUid: user.uid,
      date: new Date().toISOString(),
      model,
      action,
      fieldsModified: fields,
    },
  };

  // console.log(newLog)

  try {
    const rootRef = firebase.database().ref(`/logs/${key}`);
    const log = await rootRef.push(newLog);
    return log.key;
  } catch (err) {
    console.error(err);
    return false;
  }
};
