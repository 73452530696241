import firebase from 'firebase/app';
import { setCurrentCard } from '../redux/slices/playground';
import { dispatch } from '../redux/store';
import { createLLMAssitant, createLLMThread } from '../_apis_/connections_llm';
import { COMPANY_ID } from '../constants/selects';
import { addLLMToChatAssistant, getChatAssistant } from './ChatAssistantService';
// import { createLog } from '../redux/slices/log';

const CHAT_ASSISTANTS_BASE_URL = 'chat_assistants_playground_branch';

const getCardBaseUrl = (companyId, assistantId) => `${CHAT_ASSISTANTS_BASE_URL}/${companyId}/${assistantId}`;

const setCard = (name, element, card) => {
  const newCard = {
    ...card,
    [name]: { ...(card[name] || {}), [element.id]: element },
  };

  dispatch(setCurrentCard(newCard));
};

export const addFileOnCard = async (companyId, cardId, file, card) => {
  const cardBaseUrl = getCardBaseUrl(companyId, cardId);
  const updates = {};
  updates[`/${cardBaseUrl}/files_path/${file.id}`] = file;
  await firebase.database().ref().update(updates);

  setCard('files_path', file, card);
};

export const getUrlFileOfAnAttachment = async (companyId, cardId, idEncoded) => {
  const cardBaseUrl = getCardBaseUrl(companyId, cardId);

  const rootRef = await firebase
    .database()
    .ref(`/${cardBaseUrl}/files_path/${idEncoded}`)
    .once('value')
    .then((snap) => snap.val());
  return rootRef;
};

// conversation
export async function getCurrentConversation(companyId, cardId) {
  const cardBaseUrl = getCardBaseUrl(companyId, cardId);
  const rootRef = await firebase
    .database()
    .ref(`${cardBaseUrl}/conversation`)
    .once('value')
    .then((snap) => snap.val());
  return rootRef;
}

export const createNoteConversation = async (companyId, conversation, cardId) => {
  const cardBaseUrl = getCardBaseUrl(companyId, cardId);
  const boardRef = firebase.database().ref(`${cardBaseUrl}/conversation`);

  // const updates = {};
  // updates[`/${CHAT_ASSISTANTS_BASE_URL}/${companyId}/${boardId}`] = { id: boardId, ...board };
  // await firebase.database().ref().update(updates);
  // console.log('create board', boardId);
  const newBoard = {
    ...conversation,
    id: cardId,
    companyId,
  };
  await boardRef.set(newBoard);
  return newBoard;
};

export const sendNote = async (companyId, cardId, newMessages) => {
  const cardBaseUrl = getCardBaseUrl(companyId, cardId);
  const boardRef = firebase.database().ref(`${cardBaseUrl}/conversation/messages`);
  await boardRef.set(newMessages);

  // bug del listening
  if (newMessages.length === 1) {
    await boardRef.set([...newMessages, newMessages[0]]);
    await boardRef.set(newMessages);
  }
};

export const addParticipantIntoCard = async (companyId, cardId, participants) => {
  const cardBaseUrl = getCardBaseUrl(companyId, cardId);
  const boardRef = firebase.database().ref(`${cardBaseUrl}/conversation/participants`);
  console.log('participants addParticipantIntoCard', participants);
  await boardRef.set(participants);

  // bug del listening
  if (participants.length === 1) {
    console.log('one', [...participants, participants[0]]);
    await boardRef.set([...participants, participants[0]]);
    console.log('one 1', participants);
    await boardRef.set(participants);
  }
};

export const listenForChangesInNoteConversation = async (branchId, cardId, callback) => {
  const cardBaseUrl = getCardBaseUrl(branchId, cardId);

  const myTable = firebase.database().ref(`/${cardBaseUrl}/conversation`);
  myTable.on('child_changed', async (snapshot) => {
    const data = snapshot.val();
    console.log('data Realtime child_changed', data);
    callback(data);
  });

  return myTable;
};

export const clearListeningNotesConversation = (companyId, cardId) => {
  const cardBaseUrl = getCardBaseUrl(companyId, cardId);
  const myTable = firebase.database().ref(`/${cardBaseUrl}/conversation`);
  myTable.off('child_changed');
};

export const getPublishedAssistantLLM = async (branchId, chatAssistant, currentConnection) => {
  let llm = chatAssistant.llm || {};
  const apiKey = currentConnection.token;
  const modelProvider = currentConnection.modelProviderId;

  const isAssistantCreated = llm.assistantId;
  if (!isAssistantCreated) {
    const { assistantName } = chatAssistant;
    const { prompt, firstMessage } = chatAssistant;
    const messagePrompt = `${firstMessage}, ${prompt}`;
    const model = currentConnection.modelId;
    const assistantResult = await createLLMAssitant(apiKey, modelProvider, assistantName, messagePrompt, model);
    if (assistantResult.data.code >= 200 && assistantResult.data.code < 300) {
      const assistantId = assistantResult.data.id;
      llm = { ...llm, assistantId };
      console.log('llm assistant', llm);
      await addLLMToChatAssistant(COMPANY_ID, chatAssistant, llm);
    } else {
      console.error('No ha sido posible crear el asistente');
      return llm;
    }
  }
  const chatA = await getChatAssistant(branchId, chatAssistant.id);
  const threadId = chatA.length > 0 ? chatA[0].threadId || '' : '';
  if (threadId) {
    return { ...llm, threadId };
  }

  const threadResult = await createLLMThread(apiKey, modelProvider);
  // console.log('threadResult', threadResult);
  if (threadResult.data.code >= 200 && threadResult.data.code < 300) {
    llm = { ...llm, threadId: threadResult.data.id };
    console.log('llm thread', llm);
    await addLLMToChatAssistant(branchId, chatAssistant, llm);
  } else {
    console.error('No ha sido posible crear el thread');
  }

  return llm;
};
