import moment from 'moment';

export function castPayPalStringToDate(myDate) {
  if (myDate) {
    const parts = myDate.split('T');
    const dates = parts[0].split('-');
    const hours = parts[1].split(':');
    const newDate = new Date(dates[0], dates[1] - 1, dates[2], hours[0], hours[1]);
    return newDate;
  }
  return myDate;
}

export function castPayPalStringToDateWithFormat(myDate) {
  return moment(castPayPalStringToDate(myDate)).format('DD/MM/YY');
}
