import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import 'firebase/storage';

export const storage = firebase.storage();

export function saveFile(file, fileName, path) {
  const uploadTask = storage.ref(path).child(fileName).put(file);
  return uploadTask;
}



export async function getFilesPath(path) {
  const storageRef = storage.ref(path);
  const files = await storageRef.listAll();

  const fileData = await Promise.all(
    files.items.map(async (fileRef) => {
      const url = await fileRef.getDownloadURL();
      const metadata = await fileRef.getMetadata();

      // Extraer la extensión del archivo
      const extension = fileRef.name.split(".").pop().toLowerCase();

      // Determinar si es imagen o documento
      const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg"];
      const documentExtensions = ["pdf", "doc", "docx", "xls", "xlsx", "ppt", "pptx", "txt"];

      let format = "other";
      if (imageExtensions.includes(extension)) {
        format = "image";
      } else if (documentExtensions.includes(extension)) {
        format = "doc";
      }

      return {
        id: fileRef.name,
        name: fileRef.name,
        path: url,
       url,
        preview: url,
        size: metadata.size,
        createdAt: metadata.timeCreated,
        modifiedAt: metadata.updated,
        type: extension, // Extensión del archivo
        format, // Si es imagen o documento
      };
    })
  );

  console.log("uploadTask", fileData);
  return fileData;
}




export function getFiles(path) {
  const uploadTask = storage.ref(path);
  console.log('uploadTask', uploadTask);
}
