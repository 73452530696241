import { useSnackbar } from 'notistack';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUsersByCompany } from '../../../services/UserService';
import TableList from '../../../components/ui/Table/TableList';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import useLocales from '../../../hooks/useLocales';
import { deleteRole, getAvailableRoles } from '../../../services/RoleService';

const RolesList = () => {
  const { id } = useSelector((state) => state.company.currentCompany);
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { translate } = useLocales();
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const TABLE_HEAD = [
    { id: 'name', label: translate('Table.button31'), alignRight: false },
    { id: 'description', label: translate('Table.button32'), alignRight: false },
    { id: '', label: '', alignRight: false },
  ];

  const validateSomeUserHasRole = async (roleId) => {
    const users = await getUsersByCompany(id);
    let flag = false;
    users.on('value', (snapshot) => {
      const data = snapshot.val();
      const newList = Object.entries(data).map((el) => ({ ...el[1], id: el[0] }));

      // eslint-disable-next-line consistent-return
      newList.forEach((el) => {
        if (el.roleId === roleId) {
          flag = true;
          return true;
        }
      });
      return false;
    });

    return flag;
  };

  const handleDelete = async (roleId) => {
    const flag = await validateSomeUserHasRole(roleId);
    if (flag) {
      enqueueSnackbar(`Para eliminar el rol ningún usuario debe poseerlo`, {
        variant: 'error',
      });
      return;
    }

    await deleteRole(roleId, id);
    const newList = roles.filter((el) => el.id !== roleId);
    setRoles(newList);

    enqueueSnackbar(`Rol eliminado`, {
      variant: 'success',
    });
  };

  const getRoles = useCallback(async () => {
    try {
      const roles = await getAvailableRoles(id);

      console.log('ROLES', roles);

      if (isMountedRef.current) {
        // if (roles) setRoles(Object.entries(roles).sort((a, b) => new Date(b.date) - new Date(a.date)));
        const newList = Object.entries(roles).map((el) => ({ ...el[1], id: el[0] }));
        setRoles(newList);
      }
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  }, [isMountedRef, id]);

  return (
    <TableList
      TABLE_HEAD={TABLE_HEAD}
      getDocuments={getRoles}
      handleDelete={handleDelete}
      nameInSpanish="Rol"
      nameInEnglish="role"
      isMasculine={Boolean(false)}
      needMassiveCharge={Boolean(false)}
      isLoading={isLoading}
      documents={roles}
      nameDocument="name"
    />
  );
};

export default RolesList;
