import axios from 'axios';

const API_KEY = '10a6308a42fe54bedad1925e0cd79a75'; // 'a858171954cb95a641b207e0c9cf655a';
const IMAGE_HOST_URL = 'https://api.imgbb.com';

const uploadPhoto = async (image) => {
  let newImage;
  try {
    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    };

    const url = new URL(`${IMAGE_HOST_URL}/1/upload?key=${API_KEY}`);
    const formData = new FormData();
    formData.append('image', image);

    const response = await axios.post(url, formData, config);
    newImage = response.data.data.url;
    return newImage;
  } catch (error) {
    console.log(error);
  }
};

export { uploadPhoto };
