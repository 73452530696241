import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { deleteUserInCompany, editUser, getUserByUid, getUsersByCompany } from '../../../services/UserService';
import TableList from '../../../components/ui/Table/TableList';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
import validateUserHasPermission from '../../../utils/validateUserHasPermission';
import { dispatch } from '../../../redux/store';
import useLocales from '../../../hooks/useLocales';
import { setCurrentBranch, setCurrentCompany } from '../../../redux/slices/company';

const UsersList = ({ permissions }) => {
  // const {
  //   userProfile: { uid },
  // } = useSelector((state) => state.user);
  const { currentCompany } = useSelector((state) => state.company);
  const { myProfile } = useSelector((state) => state.user);
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const { translate } = useLocales();
  const history = useHistory();

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const TABLE_HEAD = [
    { id: 'name', label: translate('nombre.button'), alignRight: false },
    { id: 'email', label: translate('register.email'), alignRight: false },
    { id: '', label: '' },
  ];

  useEffect(() => {
    const getUsers = async () => {
      try {
        // const users = await getOwnUsers(uid);
        const users = await getUsersByCompany(currentCompany.id);
        users.on('value', (snapshot) => {
          const data = snapshot.val();
          if (isMountedRef.current && data) {
            const newList = Object.entries(data).map((el) => ({ ...el[1], id: el[0] }));
            const filteredList = newList.filter((el) => el.banished !== true);
            setUsers(filteredList);
          }
        });
      } catch (err) {
        console.error(err);
      }
      setIsLoading(false);
    };

    getUsers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getUserWithoutCompany = (user, companyId) => {
    const { companies } = user;
    delete companies[companyId];
    const newUser = {
      ...user,
      companies,
    };
    return newUser;
  };

  const callValidatePermission = async (permission, message) => {
    const flag = await validateUserHasPermission(myProfile, currentCompany.id, permission, permissions);
    if (!flag) {
      enqueueSnackbar(message, {
        variant: 'error',
      });
    }
    return flag;
  };

  const handleDelete = async (id) => {
    const flag = await callValidatePermission('delete-user', `No tiene permiso para eliminar usuarios`);
    if (flag) {
      let userToDelete = {};
      const newList = [];
      users.forEach((el) => {
        if (el.id !== id) {
          newList.push(el);
        } else {
          userToDelete = el;
        }
      });
      console.log('userToDelete', userToDelete);
      setUsers(newList);

      const user = await getUserByUid(id);
      console.log('user', user);

      const userWithoutCompany = getUserWithoutCompany(user, currentCompany.id);
      console.log('userWithoutCompany', userWithoutCompany);

      await editUser(id, userWithoutCompany);
      await deleteUserInCompany(currentCompany.id, id, true, userToDelete.name, 'delete');

      enqueueSnackbar(`Usuario eliminado`, {
        variant: 'success',
      });

      if (id === myProfile.uid) {
        dispatch(setCurrentCompany({}));
        dispatch(setCurrentBranch({}));
        history.push('/dashboard/general');
      }
    }
  };

  const redirectInviteUserPage = async () => {
    const flag = await callValidatePermission('create-user', `No tiene permiso para invitar usuarios`);
    if (flag) {
      history.push('/dashboard/user/invite');
    }
  };

  const handleCreateUser = async () => {
    const flag = await callValidatePermission('create-user', `No tiene permiso para crear usuarios`);
    if (flag) {
      history.push(`/dashboard/user/create`);
    }
  };

  const handleEditUser = async (item, id) => {
    const flag = await callValidatePermission('create-user', `No tiene permiso para crear usuarios`);
    if (flag) {
      history.push(`/dashboard/user/${id}/edit`);
    }
  };

  const handleDuplicateUser = async (item, id) => {
    const flag = await callValidatePermission('create-user', `No tiene permiso para duplicar usuarios`);
    if (flag) {
      history.push(`/dashboard/user/${id}/duplicate`);
    }
  };

  return (
    <TableList
      TABLE_HEAD={TABLE_HEAD}
      // getDocuments={getUsers}
      handleDelete={handleDelete}
      nameInSpanish="Usuario"
      nameInEnglish="user"
      isMasculine={Boolean(false)}
      needMassiveCharge={Boolean(false)}
      isLoading={isLoading}
      documents={users}
      anotherButton={redirectInviteUserPage}
      handleCreateItem={handleCreateUser}
      handleEditItem={handleEditUser}
      handleDuplicateItem={handleDuplicateUser}
      anotherTextButton="Invitar usuario"
      nameDocument="name"
    />
  );
};

UsersList.propTypes = {
  permissions: PropTypes.object,
};

export default UsersList;
