import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Grid,
  Button,
  IconButton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import EditIcon from '@mui/icons-material/Edit';
import { Signer } from 'haciendacostarica-signer';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CheckIcon from '@mui/icons-material/Check';
import ErrorIcon from '@mui/icons-material/Error';
import useLocales from '../../../../hooks/useLocales';
import CustomDialog from '../../../../components/ui/Dialog/CustomDialog';
// import FindReplace from '@material-ui/icons/FindReplace';
// import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import { FormProvider, RHFTextField } from '../../../../components/hook-form';
import { updateCompany } from '../../../../redux/slices/company';
import { getCompany } from '../../../../services/CompanyService';
import UploadSingleFile from './UploadSingleFile';

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
}));

function CompanyHacienda() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [company, setCompany] = useState({});
  const [base64, setBase64] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  const [openFinalModal, setOpenFinalModal] = useState(false);
  const { translate } = useLocales();
  // file storage
  const [files, setFiles] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const defaultValues = useMemo(
    () => ({
      username: company?.username || '',
      password: company?.password || '',
      pin: company?.pin || '',
      keyFile: [],
      KeySingnerBase64: company?.KeySingnerBase64 || '',
      verifyKey: company?.verifyKey || '',
      expiresKey: company?.expiresKey || '',
    }),
    [company]
  );
  const fetchCompany = async () => {
    await getCompany(id).then((data) => {
      setCompany(data);
      // console.log(data);
    });
  };
  useEffect(() => {
    fetchCompany();

    // reset(defaultValues);
  }, [id]);

  useEffect(() => {
    reset(defaultValues);
    // console.log(defaultValues);
  }, [company]);

  const handleUpdateCompany = async (valuesToSave) => {
    await dispatch(updateCompany(valuesToSave));
  };

  const methods = useForm({
    // resolver: yupResolver(NewArticleSchema),
    defaultValues,
  });

  const getBase64 = (file) =>
    new Promise((resolve) => {
      let fileInfo;
      let baseURL = '';
      // Make new FileReader
      const reader = new FileReader();

      // Convert the file to base64 text
      reader.readAsDataURL(file);

      // on reader load somthing...
      reader.onload = () => {
        // Make a fileInfo Object
        console.log('Called', reader);
        baseURL = reader.result;
        console.log('url', baseURL);
        resolve(baseURL);
      };
      console.log('Called', fileInfo);
    });

  const {
    reset,
    watch,
    // control,
    // setValue,
    // getValues,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  const onSubmit = async () => {
    if (isDisabled && openFinalModal === false) {
      setIsDisabled(false);
    } else if (isDisabled === false || openFinalModal) {
      console.log('files', files);
      if (files === null || files === undefined || files.length <= 0) {
        const valuesToSave = {
          username: values.username,
          password: values.password,
          pin: values.pin,
          id,
          KeySingnerBase64: values.KeySingnerBase64,
          verifyKey: values.verifyKey,
          expiresKey: values.expiresKey,
        };
        if (values.pin !== company.pin) {
          valuesToSave.verifyKey = '';
        }

        dispatch(updateCompany(valuesToSave));
        setCompany({ ...company, ...valuesToSave });
      } else {
        await getBase64(files[0])
          .then((result) => {
            const valuesToSave = {
              username: values.username,
              password: values.password,
              pin: values.pin,
              id,
              KeySingnerBase64: result.split(',')[1],
              verifyKey: '',
              expiresKey: '',
            };

            dispatch(updateCompany(valuesToSave));
            setCompany({ ...company, ...valuesToSave });
          })
          .catch((err) => {
            console.log(err);
          });
      }

      /* 
      if (values.keyFile.length > 0) {
        const fileName = `${Date.now()}-${values.keyFile[0].name}`;
        const uploadTask = saveFile(values.keyFile[0], fileName, `keys/${id}`);

        uploadTask.on('stage_changed', null, null, async () => {
          const urlCert = await storage.ref(`keys/${id}`).child(fileName).getDownloadURL();
          await dispatch(updateCompany({ urlCert }));
        });
      }

      */

      enqueueSnackbar(`Certificado Digital editado exitosamente `, {
        variant: 'success',
      });
      setIsDisabled(true);
      // console.log(data);
      setOpenFinalModal(false);
    }
    setTimeout(async () => {
      const data = await getCompany(id);
      console.log(data);
      setCompany(data);
    }, 8000);

    // enqueueSnackbar(`Company : ${values.companyName} was ${'Updated'} successfully`, {
    //   variant: 'success',
    // });
    // setIsEdit(false);
    // if (isMountedRef.current) {
    //   setSubmitting(false);
    // }
  };

  // const handleDrop = useCallback(
  //   (acceptedFiles) => {
  //     setValue(
  //       'keyFile',
  //       acceptedFiles.map((file) =>
  //         Object.assign(file, {
  //           preview: URL.createObjectURL(file),
  //         })
  //       )
  //     );
  //   },
  //   [setValue]
  // );

  // const handleRemoveAll = () => {
  //   setValue('keyFile', []);
  // };

  // const handleRemove = (file) => {
  //   const filteredItems = values.keyFile?.filter((_file) => _file !== file);
  //   setValue('keyFile', filteredItems);
  // };

  // const handleReplace = (rowDate) => {
  // setOpenModal(!openModal);
  // setReplaceFile(rowDate);
  //   console.log('replace', rowDate);
  // };

  // const handleConfirmReplace = (replaceFile) => {
  // handleDelete(replaceFile);
  // setOpenModal(!openModal);
  //   console.log('replace', replaceFile);
  // };

  // const handleDelete = (rowDate, rowFileName) => {
  // const dataValuesCopy = { ...dataValues };
  // const findItem = dataValuesCopy.documents[documentType].find(
  //   (item) => item.date === rowDate
  // );
  // findItem.active = false;
  // setDataValues({
  //   ...dataValuesCopy,
  //   update: [
  //     {
  //       userData: userProfile,
  //       date: Date.now(),
  //       documents: `${[documentTypeName]}: Eliminacion de ${rowFileName}`
  //     },
  //     dataValuesCopy.update
  //   ].flat()
  // });

  //   console.log('delete', rowDate, rowFileName);
  // };

  const handleListContent = (row) =>
    row?.urlFile && (
      <TableRow key={row.date}>
        <TableCell component="th" scope="row">
          {row.fileName}
        </TableCell>
        <TableCell>{new Date(row.date).toLocaleString()}</TableCell>
        <TableCell>
          {row.date !== null && (
            <>
              <Tooltip title="Descargar">
                <IconButton>
                  <a href={row.urlFile} download target="_blank" rel="noopener noreferrer">
                    <CloudDownloadIcon />
                  </a>
                </IconButton>
              </Tooltip>
            </>
          )}
        </TableCell>
      </TableRow>
    );

  const renderDetailForm = () => (
    <>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={6}>
          <Card sx={{ mt: 2, paddingBlockEnd: 1, marginTop: 1 }}>
            <CardHeader
              title={
                <Grid spacing={3}>
                  <Grid container justifyContent="flex-end" alignItems="center" item spacing={3}>
                    <IconButton onClick={() => setOpenFinalModal(true)}>
                      <EditIcon />
                    </IconButton>
                  </Grid>
                </Grid>
              }
            />

            <CardContent>
              <Typography variant="body1" gutterBottom component="div">
                {translate('Table.button19')} <strong>{values.username}</strong>
              </Typography>
              <Typography variant="body1" gutterBottom component="div">
                {translate('Table.button20')} <strong>{values.password}</strong>
              </Typography>
              <Typography variant="body1" gutterBottom component="div">
                {translate('Table.button21')} <strong>{values.pin}</strong>
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card sx={{ mt: 2, paddingBlockEnd: 6, marginTop: 1 }}>
            <CardContent>
              <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                <Grid item xs={6}>
                  <Typography variant="body1" gutterBottom component="div">
                    {translate('Table.button22')}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {values.verifyKey === '' && (
                    <Tooltip title="verifyKey">
                      <Box sx={{ display: 'flex' }}>
                        <CircularProgress />
                      </Box>
                    </Tooltip>
                  )}{' '}
                  {values.verifyKey === 'valido' && (
                    <>
                      <Tooltip title="verifyKey">
                        <IconButton>
                          <a download target="_blank" rel="noopener noreferrer">
                            <CheckIcon />
                          </a>
                          <Typography variant="body1" gutterBottom component="div">
                            {translate('Table.button23')}
                          </Typography>
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  {values.verifyKey === 'no valido' && (
                    <>
                      <Tooltip title="verifyKey">
                        <IconButton>
                          <a download target="_blank" rel="noopener noreferrer">
                            <ErrorIcon />
                          </a>
                          <Typography variant="body1" gutterBottom component="div">
                            {translate('Table.button24')}
                          </Typography>
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="body1" gutterBottom component="div">
                    {translate('Table.button25')}
                  </Typography>
                </Grid>

                <Grid item xs={6}>
                  <strong>
                    {values.verifyKey === 'valido' && <>{new Date(values.expiresKey).toLocaleString()}</>}
                  </strong>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );

  const renderDetailKeySignerForm = () => (
    <>
      <Box sx={{ p: 3 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{translate('Table.button26')}</TableCell>
              <TableCell>{translate('Table.button27')}</TableCell>
              <TableCell>{translate('Table.button28')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {company?.documents?.keys /* .filter((item) => item.active !== false) */
              .map((row) => handleListContent(row))}
          </TableBody>
        </Table>
      </Box>
    </>
  );

  // eslint-disable-next-line arrow-body-style
  const renderEditForm = () => {
    return (
      <>
        <br />
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12}>
            <RHFTextField fullWidth label={translate('Table.button19')} name="username" />
          </Grid>
          <Grid item xs={12}>
            <RHFTextField fullWidth label={translate('Table.button20')} name="password" />
          </Grid>

          <Grid item xs={12}>
            <RHFTextField fullWidth label={translate('Table.button21')} name="pin" />
          </Grid>
        </Grid>
        <br />
        <br />

        {/* <div>
            <LabelStyle>Certificado .pk12</LabelStyle>
            <RHFUploadMultiFile
                      name="keyFile"
                      showPreview={false}
                      accept="image/*"
                      maxSize={3145728}
                      onDrop={handleDrop}
                      onRemove={handleRemove}
                      onRemoveAll={handleRemoveAll}
                    />
          </div> */}
      </>
    );
  };
  const renderEditKeySignerForm = () => (
    <>
      {/* <div>
            <LabelStyle>Certificado .pk12</LabelStyle>
            <RHFUploadMultiFile
                      name="keyFile"
                      showPreview={false}
                      accept="image/*"
                      maxSize={3145728}
                      onDrop={handleDrop}
                      onRemove={handleRemove}
                      onRemoveAll={handleRemoveAll}
                    />
          </div> */}
    </>
  );

  return (
    <>
      {/* <Page title="Certificado Digital">
      <Container>
         <HeaderDashboard
          heading="Certificado Digital"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            // { name: 'User', href: PATH_DASHBOARD.user.root },
            { name: 'Hacienda' },
          ]}
        /> */}
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        {openFinalModal && (
          <CustomDialog open={openFinalModal} title={translate('Table.edit')} onHide={() => setOpenFinalModal(false)}>
            {renderEditForm()}
            <Grid item xs={12}>
              <Button type="submit" variant="contained" size="large" onClick={handleSubmit(onSubmit)}>
                {translate('Table.button29')}{' '}
              </Button>
            </Grid>
          </CustomDialog>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>{renderDetailForm()}</Stack>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ p: 3 }}>
              <Stack spacing={3}>
                {isDisabled ? renderDetailKeySignerForm() : renderEditKeySignerForm()}

                {!isDisabled ? (
                  <>
                    <LabelStyle>{translate('Table.button30')}</LabelStyle>
                    <UploadSingleFile
                      value={files}
                      onChange={setFiles}
                      dataValues={company}
                      setDataValues={setCompany}
                      documentType={id}
                      handleUpdate={handleUpdateCompany}
                    />
                  </>
                ) : null}
                <LoadingButton
                  className={isSubmitting ? '' : ''}
                  type="submit"
                  variant="contained"
                  size="large"
                  loading={isSubmitting}
                >
                  {isDisabled ? translate('Table.edit') : 'Guardar'}
                </LoadingButton>
              </Stack>
            </Card>
          </Grid>
        </Grid>
      </FormProvider>
      {/* </Container>
      </Page> */}
    </>
  );
}

CompanyHacienda.propTypes = {};

export default CompanyHacienda;
