import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Icon } from '@iconify/react';
import {
  Box,
  Card,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUsersByCompany } from '../../../../services/UserService';
import CompanyLogModal from '../../../../components/administration/company/CompanyLogModal';
import Scrollbar from '../../../../components/Scrollbar';
import SearchNotFound from '../../../../components/SearchNotFound';
import UserListHead from '../../../../components/user/list/UserListHead';
import UserListToolbar from '../../../../components/user/list/UserListToolbar';
import { getLogsByModel } from '../../../../redux/slices/log';
import { getInvoiceViewsByBranch } from '../../../../services/PersonalizedViewService';
import useLocales from '../../../../hooks/useLocales';

// import { getCompany } from '../../../../services/CompanyService';

const CompanyLoguser = ({ company }) => {
  console.log('company3', company);
  const { myProfile, userProfile } = useSelector((state) => state.user);
  const { translate } = useLocales();
  const { currentBranch } = useSelector((state) => state.company);
  const { currentCompany } = useSelector((state) => state.company);
  const [logs, setLogs] = useState([]);
  console.log('logslogs', logs);
  console.log('logslogsmyProfile', userProfile);
  // PAGINATION
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  // eslint-disable-next-line no-unused-vars
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openModal, setOpenModal] = useState(false);
  const [showLogModal, setShowLogModal] = useState({});

  const TABLE_HEAD = [
    { id: 'date', label: translate('Table.back'), alignRight: false },
    { id: 'user', label: translate('Table.message'), alignRight: false },
    { id: 'model', label: translate('Table.button'), alignRight: false },
    { id: 'action', label: translate('Table.button2'), alignRight: false },
    { id: 'detail', label: '' },
  ];

  const getUserLogIds = async (companyId) => {
    const req = await getUsersByCompany(companyId);
    let data;
    req.on('value', (snapshot) => {
      data = snapshot.val();
    });

    const newUserIds = data ? Object.keys(data) : [];
    console.log('newUserIds', newUserIds);
    const newUserLogs = newUserIds?.map((el) => `${el}${companyId}`);
    console.log('newUserLogs', newUserLogs);
    return newUserLogs;
  };

  useEffect(() => {
    const fetchCompany = async () => {
      if (!company) return;

      const branches = company.branches ? Object.keys(company.branches) : [];
      const articles = company.articles ? company.articles : [];
      const procedures = company.procedures ? company.procedures : [];
      const clients = company.clients ? company.clients : [];
      const users = await getUserLogIds(company.id);

      const invoicesViews = Object.keys(await getInvoiceViewsByBranch(currentBranch.id)) || [];

      const logsModelId = [company.id, ...branches, ...articles, ...procedures, ...clients, ...users, ...invoicesViews];

      const logsResult = [];
      await Promise.all(
        logsModelId.map(async (id) => {
          const log = await getLogsByModel(id);
          if (log !== null) {
            logsResult.push(...Object.values(log));
          }
        })
      );

      // Filtramos los logs para obtener solo los del usuario actual
      const filteredLogs = logsResult.filter((log) => log.modifierUserUid === userProfile.uid);

      setLogs(filteredLogs.sort((a, b) => new Date(b.date) - new Date(a.date)));
    };

    fetchCompany();
  }, [company, userProfile.uid]);

  const filteredUsers = applySortFilter(logs, getComparator(order, orderBy), filterName);

  console.log('filteredUsers', filteredUsers);

  // PAGINATION
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
    // console.log(event);
  };
  const isUserNotFound = logs.length === 0;

  // const handleOpenModal = () => {
  //   setOpenModal(true);
  // };

  const handleOpenDetailModal = (content) => {
    console.log('content', content);
    setShowLogModal(content);
    setOpenModal(true);
  };

  return (
    <>
      {/* <Page title="History">
      <Container>
        <HeaderDashboard
          heading="History modification"
          links={[
            { name: 'Dashboard', href: PATH_DASHBOARD.root },
            // { name: 'User', href: PATH_DASHBOARD.user.root },
            { name: 'Logs' },
          ]}
        /> */}

      <Card sx={{ ml: -3 }}>
        <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                hasNoCheckBox={false}
                rowCount={logs.length}
                numSelected={selected.length}
                onRequestSort={handleRequestSort}
                // onSelectAllClick={() => {}}
              />
              <TableBody>
                {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                  // console.log(...Object.values(row));
                  const { id, date, model, action, modifierUserName, fieldsModified } = row;
                  // const isItemSelected = selected.indexOf(id) !== -1;

                  return (
                    <TableRow
                      hover
                      key={`${id}-${date}`}
                      tabIndex={-1}
                      sx={{ cursor: 'pointer' }}
                      onClick={() => {
                        handleOpenDetailModal(fieldsModified);
                      }}
                    >
                      <TableCell align="left">{moment(date).calendar()}</TableCell>
                      <TableCell align="left">{modifierUserName}</TableCell>
                      <TableCell align="left">{model}</TableCell>
                      <TableCell align="left">{action}</TableCell>
                      {/* Removed the IconButton column */}
                    </TableRow>
                  );
                })}
              </TableBody>
              {isUserNotFound && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={5}>
                      <Box sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={logs.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
      {openModal && <CompanyLogModal setOpenModal={setOpenModal} openModal={openModal} contentModal={showLogModal} />}
    </>
  );
};

CompanyLoguser.propTypes = {
  company: PropTypes.any,
};

export default CompanyLoguser;

// -----------------------------------------------
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return array.filter((_user) => _user.model.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}
