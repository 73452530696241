import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { setCurrentCompany, setCurrentBranch } from '../../../redux/slices/company';
import { getBranchesListByCompany, deleteBranch, getBranch } from '../../../services/BranchService';
import TableList from '../../../components/ui/Table/TableList';
import NewCompanyModal from '../../../components/company-selection/NewCompanyModal';
import validateUserHasPermission from '../../../utils/validateUserHasPermission';
import getCompanyToRedux from '../../../utils/getCompanyToRedux';
import useLocales from '../../../hooks/useLocales';

const BranchSelectorTab = ({ setSuccess, success, company, permissions }) => {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { currentBranch } = useSelector((state) => state.company);
  const { myProfile } = useSelector((state) => state.user);
  const { translate } = useLocales();
  const [isLoading, setIsLoading] = useState(true);
  const [branches, setBranches] = useState([]);
  const [isEditBranch, setIsEditBranch] = useState(false);

  const [openBranchModal, setOpenBranchModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});

  const TABLE_HEAD = [
    { id: 'name', label: translate('Table.button11'), alignRight: false },
    { id: 'address', label: translate('Table.button12'), alignRight: false },
    { id: 'type', label: translate('Table.button13'), alignRight: false },
    { id: '', label: '', alignRight: false },
  ];

  const handleCreateBranch = async () => {
    const flag = await validateUserHasPermission(myProfile, company.id, 'create-branch', permissions);
    if (!flag) {
      enqueueSnackbar(`No tiene permiso para crear sucursales`, {
        variant: 'error',
      });
      return;
    }

    setSelectedItem({});
    setIsEditBranch(false);
    setOpenBranchModal(true);
  };

  const handleEditBranch = async (item) => {
    const flag = await validateUserHasPermission(myProfile, company.id, 'edit-branch', permissions);
    if (!flag) {
      enqueueSnackbar(`No tiene permiso para editar sucursales`, {
        variant: 'error',
      });
      return;
    }

    setSelectedItem(item);
    setIsEditBranch(true);
    setOpenBranchModal(true);
  };

  const handleDuplicateBranch = async (item) => {
    const flag = await validateUserHasPermission(myProfile, company.id, 'create-branch', permissions);
    if (!flag) {
      enqueueSnackbar(`No tiene permiso para duplicar sucursales`, {
        variant: 'error',
      });
      return;
    }
    setSelectedItem(item);
    setIsEditBranch(false);
    setOpenBranchModal(true);
  };

  const handleSelectBranch = (item) => {
    const companyToRedux = getCompanyToRedux({
      ...company,
      id: company.id,
      name: company.companyName,
    });

    dispatch(setCurrentCompany(companyToRedux));
    dispatch(setCurrentBranch(item));

    enqueueSnackbar(`Sucursal: ${item.name} ${'Seleccionada'}`, {
      variant: 'success',
    });
  };

  const handleDelete = async (id) => {
    const flag = await validateUserHasPermission(myProfile, company.id, 'delete-branch', permissions);
    if (!flag) {
      enqueueSnackbar(`No tiene permiso para eliminar sucursales`, {
        variant: 'error',
      });
      return;
    }
    const branch = await getBranch(id);
    await deleteBranch(branch, id);

    if (currentBranch.id === id) dispatch(setCurrentBranch({}));

    // getBranches();
    const newList = branches.filter((el) => el.id !== id);
    setBranches(newList);
    enqueueSnackbar(`Sucursal eliminada`, {
      variant: 'success',
    });
  };

  const editBranch = (branch) => {
    let index = 0;
    let i = 0;
    branches.map((el) => {
      if (el.id === branch.id) {
        index = i;
      }
      i += 1;
      return el;
    });

    const newList = branches;
    newList[index] = branch;
    setBranches(newList);
  };

  const onBranchCreated = async (isEdit, branch) => {
    if (isEdit) {
      editBranch(branch);
    } else {
      setBranches([...branches, branch]);
    }

    if (!isEdit || currentBranch.id === branch.id) {
      dispatch(setCurrentBranch(branch));
    }
  };

  const getBranches = useCallback(async () => {
    try {
      if (company.id) {
        const branchesTemp = await getBranchesListByCompany(company);
        setBranches(branchesTemp);
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company]);
  return (
    <>
      <TableList
        TABLE_HEAD={TABLE_HEAD}
        getDocuments={getBranches}
        handleDelete={handleDelete}
        nameInSpanish={translate('Table.button14')}
        nameInEnglish="branch"
        isMasculine={Boolean(false)}
        needMassiveCharge={Boolean(false)}
        isLoading={isLoading}
        documents={branches}
        dontNeedCheckBox={Boolean(true)}
        handleCreateItem={handleCreateBranch}
        handleEditItem={handleEditBranch}
        handleDuplicateItem={handleDuplicateBranch}
        handleClickItem={handleSelectBranch}
        nameDocument="name"
      />

      {openBranchModal && (
        <NewCompanyModal
          success={success}
          setSuccess={setSuccess}
          company={selectedItem}
          isEdit={isEditBranch}
          setIsEdit={setIsEditBranch}
          setOpenModal={setOpenBranchModal}
          openModal={openBranchModal}
          type="branch"
          parentCompany={company}
          onBranchCreated={onBranchCreated}
        />
      )}
    </>
  );
};

BranchSelectorTab.propTypes = {
  success: PropTypes.bool,
  setSuccess: PropTypes.func,
  company: PropTypes.object,
  permissions: PropTypes.object,
};

export default BranchSelectorTab;
