import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import fileFill from '@iconify/icons-eva/file-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import { motion, AnimatePresence } from 'framer-motion';
import { useSnackbar } from 'notistack';

import { experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  List,
  Link,
  Button,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';

import { fData } from '../../../../utils/formatNumber';
import useAuth from '../../../../hooks/useAuth';

import { MIconButton, MLinearProgress } from '../../../../components/@material-extend';
import { varFadeInRight } from '../../../../components/animate';
import { storage } from '../../../../services/StorageService';

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': {
    opacity: 0.72,
    cursor: 'pointer',
  },
  [theme.breakpoints.up('md')]: {
    textAlign: 'left',
    flexDirection: 'row',
  },
}));

UploadMultiFile.propTypes = {
  caption: PropTypes.string,
  error: PropTypes.bool,
  value: PropTypes.array,
  onChange: PropTypes.func,
  sx: PropTypes.object,
};

export default function UploadMultiFile({
  documentType,
  setDataValues,
  dataValues,
  caption,
  error = false,
  value: files,
  onChange: setFiles,
  sx,
  handleUpdate,
  ...other
}) {
  const hasFile = files.length > 0;
  const { userProfile } = useAuth();
  const [documentTypeName, setDocumentType] = useState('keys');
  const { enqueueSnackbar } = useSnackbar();
  const [uploadProgress, setUploadProgress] = useState(0);

  // useEffect(() => {
  //   switch (documentType) {
  //     case 'sendingOrder':
  //       setDocumentType('Órden de envío');
  //       break;
  //     case 'locations':
  //       setDocumentType('Localización');
  //       break;
  //     case 'initialSettlement':
  //       setDocumentType('Liquidación inicial de proceso');
  //       break;
  //     case 'otherDocuments':
  //       setDocumentType('Otros documentos');
  //       break;
  //     case 'processDocumentation':
  //       setDocumentType('Documentos Proceso');
  //       break;
  //     default:
  //       setDocumentType('Documento');
  //       break;
  //   }
  // }, [documentType]);

  const handleDrop = useCallback(
    (acceptedFiles) => {
      setFiles(acceptedFiles);
    },
    [setFiles]
  );

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const handleRemoveFile = (file) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    onDrop: handleDrop,
  });

  const handleUpload = () => {
    files.forEach((file) => {
      uploadEachFile(file);
    });
  };

  const uploadEachFile = (file) => {
    const fileName = `${Date.now()}-${file.name}`;
    const fileNameDate = fileName;
    const uploadTask = storage.ref(`keys/${documentType}/${fileNameDate}`).put(file);
    uploadTask.on(
      'stage_changed',
      (snapshot) => {
        const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
        setUploadProgress(progress);
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref(`keys/${documentType}/`)
          .child(fileNameDate)
          .getDownloadURL()
          .then((url) => {
            const keys = [
              // dataValues?.documents?.keys,
              {
                fileName: file.name,
                urlFile: url,
                date: Date.now(),
                active: true,
                notes: '',
              },
            ].flat();

            const newValues = {
              ...dataValues,
              documents: {
                // ...dataValues.documents,
                keys,
              },
              update: [
                {
                  // userData: userProfile,
                  date: Date.now(),
                  documents: `${[documentTypeName]}: Carga de ${file.name}`,
                },
                // dataValues.update,
              ].flat(),
            };
            setDataValues(newValues);
            handleUpdate(newValues);
            enqueueSnackbar('Save success', { variant: 'success' });
          });
      }
    );
  };

  return (
    <Box sx={{ width: '100%', ...sx }} {...other}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
        }}
      >
        <input {...getInputProps()} />

        <Box
          component="img"
          alt="select file"
          src="/static/illustrations/illustration_upload.svg"
          sx={{ height: 160 }}
        />

        <Box
          sx={{
            p: 3,
            ml: { md: 2 },
          }}
        >
          <Typography gutterBottom variant="h5">
            Suelte aquí o seleccione archivos
          </Typography>

          {caption ? (
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {caption}
            </Typography>
          ) : (
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Suelte archivos aquí o haga click en &nbsp;
              <Link underline="always">navegar</Link>&nbsp;por las carpetas de su ordenador
            </Typography>
          )}
          {uploadProgress !== 0 && (
            <>
              <MLinearProgress
                variant="determinate"
                value={uploadProgress}
                valueBuffer={uploadProgress}
                sx={{ mt: 3 }}
              />
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                Cargando {uploadProgress}%
              </Typography>
            </>
          )}
        </Box>
      </DropZoneStyle>

      <List disablePadding sx={{ ...(hasFile && { my: 5 }) }}>
        <AnimatePresence>
          {files.map((file) => (
            <ListItem
              key={file.name}
              component={motion.div}
              {...varFadeInRight}
              sx={{
                my: 1,
                py: 0.5,
                px: 2,
                borderRadius: 1,
                border: (theme) => `solid 1px ${theme.palette.divider}`,
                bgcolor: 'background.paper',
              }}
            >
              <ListItemIcon>
                <Icon icon={fileFill} width={32} height={32} />
              </ListItemIcon>
              <ListItemText
                primary={file.name}
                secondary={fData(file.size)}
                primaryTypographyProps={{ variant: 'subtitle2' }}
              />
              <ListItemSecondaryAction>
                <MIconButton edge="end" size="small" onClick={() => handleRemoveFile(file)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </AnimatePresence>
      </List>

      {hasFile && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            '& > *': { ml: 1.5 },
          }}
        >
          <Button onClick={handleRemoveAll} sx={{ mr: 1.5 }}>
            Remove all
          </Button>
          <Button variant="contained" onClick={handleUpload}>
            Upload files
          </Button>
        </Box>
      )}
    </Box>
  );
}
