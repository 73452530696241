import * as React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Icon } from '@iconify/react';
// import searchFill from '@iconify/icons-eva/search-fill';
// import trash2Fill from '@iconify/icons-eva/trash-2-fill';
// import roundFilterList from '@iconify/icons-ic/round-filter-list';
import contentModified from '@iconify-icons/eos-icons/content-modified';
// import bxCopyAlt from '@iconify-icons/bx/bx-copy-alt';
import { useState } from 'react';
import { useSnackbar } from 'notistack';
// material
import { useTheme, experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Button,
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  InputAdornment,
  OutlinedInput,
  TextField,
  Card,
  Container,
  Avatar,
  CircularProgress,
  Select,
} from '@material-ui/core';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Alert from '@mui/material/Alert';
import plusFill from '@iconify/icons-eva/plus-fill';
import bxCopyAlt from '@iconify-icons/bx/bx-copy-alt';
import useLocales from '../../../hooks/useLocales';
// import BusinessIcon from '@mui/icons-material/Business'; // Puedes usar cualquier icono de tu elección
import CustomDialog from '../Dialog/CustomDialog';
import { ID_TYPE } from '../../../constants/hacienda/IDType';
import { DELETE } from '../../../constants/labels';
import Iconify from '../../iconify_4.3';
import ConfirmationModal from '../../ConfirmationModal';
import { getClientsByCompanyAndCompareCedula } from '../../../_apis_/clients';
import { getCompanyById } from '../../../_apis_/companies';
// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  // justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 240,
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

ToolbarComponent.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  id: PropTypes.string,
  handleDelete: PropTypes.func,
  handleEditItem: PropTypes.func,
  handleDuplicateItem: PropTypes.func,
  setSelected: PropTypes.func,
  name: PropTypes.string,
  isMasculine: PropTypes.bool,
  nameInEnglish: PropTypes.string,
  needMassiveCharge: PropTypes.bool,
  urlToDownloadSheet: PropTypes.string,
  dontNeedCreateButton: PropTypes.bool,
  dontNeedDuplicateButton: PropTypes.bool,
  handleCreateItem: PropTypes.func,
  anotherToolbarComponent: PropTypes.func,
  anotherButton: PropTypes.func,
  anotherTextButton: PropTypes.string,
  handleExportAll: PropTypes.func,
};

export default function ToolbarComponent({
  numSelected,
  filterName,
  onFilterName,
  id,
  handleDelete,
  handleEditItem,
  handleDuplicateItem,
  setSelected,
  name,
  isMasculine,
  nameInEnglish,
  needMassiveCharge,
  urlToDownloadSheet,
  dontNeedCreateButton,
  dontNeedDuplicateButton,
  handleCreateItem,
  anotherToolbarComponent,
  anotherButton,
  anotherTextButton,
  handleExportAll,
}) {
  const theme = useTheme();
  const isLight = theme.palette.mode === 'light';
  const [isLoading, setIsLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { currentCompany } = useSelector((state) => state.company);
  const [openTo, setOpenTo] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [datos, setDatos] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [cedula, setCedula] = useState('');
  const [taxAlerts, setTaxAlerts] = useState('');
  const { translate } = useLocales(); // Usamos el hook para traducir
  const [clientSelectd, setClientSelectd] = useState();
  const [enBaseDeDatos, setEnBaseDeDatos] = useState(false);
  const history = useHistory();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const cargaMasiva = () => {
    history.push(`/dashboard/${nameInEnglish}/massiveUpload`);
  };

  const removeItem = () => {
    setOpenModal(!openModal);
    setSelected([]);
    handleDelete(id);
  };

  const editItem = () => {
    if (handleEditItem) {
      handleEditItem(id);
    } else {
      history.push(`/dashboard/${nameInEnglish}/${id}/edit`);
    }
  };

  const duplicateItem = () => {
    if (handleDuplicateItem) {
      handleDuplicateItem(id);
    } else {
      history.push(`/dashboard/${nameInEnglish}/${id}/duplicate`);
    }
  };

  const handleButtonClick = async () => {
    setIsLoading(true); // Activar estado de cargando
    const company = await getCompanyById(currentCompany.id);

    console.log('company', company);
    try {
      if (company.country === 'cr') {
        // Define el tiempo máximo de espera en milisegundos (por ejemplo, 10 segundos)
        const timeout = 5000;

        // Crear una promesa que rechaza después de `timeout` milisegundos
        const timeoutPromise = new Promise((_, reject) =>
          setTimeout(() => reject(new Error('Timeout: servicio de hacienda no disponible en este momento')), timeout)
        );

        // Realizar la solicitud `fetch` con un timeout
        const response = await Promise.race([
          fetch(`https://api.hacienda.go.cr/fe/ae?identificacion=${cedula}`),
          timeoutPromise,
        ]);

        console.log('response', response);
        // Verifica si la respuesta es exitosa (status 200-299)
        if (!response.ok) {
          setTaxAlerts('Error al obtener datos o el servicio de hacienda no disponible en este momento');
          return; // Detener la ejecución si hubo un error en la respuesta
        }

        const data = await response.json();
        setDatos(data);

        if (data.code) {
          setTaxAlerts('No encontrado');
        }

        console.log('company', currentCompany);

        const clients = await getClientsByCompanyAndCompareCedula(company, cedula);
        console.log('clients', clients);

        // Verifica si la cédula existe en los resultados obtenidos
        const cedulaEnResultados = clients.some((client) => client.identificationNumber === cedula);

        if (clients.length > 0) {
          // La cédula existe en los resultados
          setEnBaseDeDatos(true);
          await setClientSelectd(clients[0]);
        } else {
          // La cédula no existe en los resultados
          setEnBaseDeDatos(false);
        }
      } else {
        setTaxAlerts('Servicio no disponible en este momento');
      }
    } catch (error) {
      // Muestra una notificación en caso de un error o timeout
      if (error.message === 'Timeout: La solicitud tomó demasiado tiempo') {
        setTaxAlerts('La solicitud a la API está tardando demasiado');
      } else {
        setTaxAlerts(`Error al obtener datos ${error}`);
      }
      console.error('Error al obtener datos:', error);
    } finally {
      setIsLoading(false); // Desactivar estado de cargando
    }
  };

  const onSelectedMethod = () => {
    if (enBaseDeDatos) {
      // Redirige a un lugar si está seleccionado
      history.push(`/dashboard/${nameInEnglish}/${clientSelectd.id}/detail`);
    } else {
      // Realiza alguna acción o redirige a otro lugar si no está seleccionado
      history.push(`/dashboard/${nameInEnglish}/create/${cedula}`);
    }
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: isLight ? 'primary.main' : 'text.primary',
          bgcolor: isLight ? 'primary.lighter' : 'primary.dark',
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      ) : (
        <>
          <SearchStyle
            sx={{ mb: -3 }}
            value={filterName}
            onChange={onFilterName}
            placeholder={`${translate('welcome6.button')} ${name}...`}
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon={'eva:search-fill'} sx={{ color: 'text.disabled' }} width={20} height={20} />
              </InputAdornment>
            }
          />
        </>
      )}
      {nameInEnglish === 'directory' && (
        <Tooltip title="Modify">
          <IconButton onClick={() => setOpenTo(true)}>
            <Icon icon="ph:magnifying-glass-fill" />
          </IconButton>
        </Tooltip>
      )}
      {numSelected > 0 ? (
        <>
          {id !== null && (
            <Tooltip title="Modify">
              <IconButton onClick={editItem}>
                <Icon icon={contentModified} />
              </IconButton>
            </Tooltip>
          )}
          {console.log('!dontNeedDuplicateButton', !dontNeedDuplicateButton)}
          {id !== null && !dontNeedDuplicateButton && (
            <Tooltip title="Clone">
              <IconButton onClick={duplicateItem}>
                <Icon icon={bxCopyAlt} />
              </IconButton>
            </Tooltip>
          )}
          {id !== null && (
            <Tooltip title="Delete">
              <IconButton onClick={() => setOpenModal(!openModal)}>
                <Iconify icon={'eva:trash-2-fill'} width={20} height={20} />
              </IconButton>
            </Tooltip>
          )}
        </>
      ) : (
        <>
          {anotherToolbarComponent && anotherToolbarComponent()}

          {anotherButton && (
            <Button variant="contained" onClick={anotherButton} sx={{ ml: 'auto', mr: 2 }}>
              {anotherTextButton}
            </Button>
          )}
          <CustomDialog
            title={`Buscar en hacienda `}
            open={openTo}
            onHide={() => {
              setOpenTo(false);
              setDatos([]);
              setCedula('');
              setTaxAlerts('');
            }}
            maxWidth="sm"
            needCancelButton={Boolean(true)}
            cancelTextButton="Cerrar"
          >
            <Container
              maxWidth="sm"
              style={{
                marginTop: '20px',
                position: 'relative',
              }}
            >
              <TextField
                label="Número de cédula"
                variant="outlined"
                value={cedula}
                onChange={(e) => setCedula(e.target.value)}
                fullWidth
                style={{ marginBottom: '10px' }}
              />

              <Button
                style={{ marginBlockEnd: 10 }}
                disabled={isLoading}
                startIcon={isLoading ? <CircularProgress size={20} /> : null}
                variant="contained"
                onClick={handleButtonClick}
                fullWidth
              >
                {isLoading ? 'Consultando...' : 'Buscar Datos'} {/* Mostrar texto dependiendo del estado */}
              </Button>

              {datos?.nombre && (
                <FollowerItem follower={datos} onSelected={enBaseDeDatos} onSelectedMethod={onSelectedMethod} />
              )}

              {taxAlerts && (
                <Alert variant="filled" severity="warning">
                  {taxAlerts}
                </Alert>
              )}
            </Container>
          </CustomDialog>

          {(needMassiveCharge || handleExportAll) && (
            <>
              <Button
                id="basic-button"
                variant="outlined"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ ml: 2 }}
              >
                +
              </Button>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                {needMassiveCharge && (
                  <MenuItem onClick={handleClose}>
                    <a
                      href={urlToDownloadSheet}
                      style={{ textDecoration: 'none', color: 'black', fontWeight: 'normal' }}
                    >
                      Descargar plantilla
                    </a>
                  </MenuItem>
                )}
                {needMassiveCharge && <MenuItem onClick={cargaMasiva}>Carga masiva</MenuItem>}
                {handleExportAll && <MenuItem onClick={handleExportAll}>Exportar {name}s</MenuItem>}
              </Menu>
            </>
          )}
        </>
      )}
      <ConfirmationModal
        setOpenModal={setOpenModal}
        openModal={openModal}
        onConfirm={removeItem}
        title={DELETE(name, isMasculine).title}
        subtitle={DELETE(name, isMasculine).message}
      />
    </RootStyle>
  );
}

function FollowerItem({ follower, selected, onSelected, onSelectedMethod }) {
  const { nombre, tipoIdentificacion, situacion, actividades } = follower;

  return (
    <Card
      sx={{
        paddingY: 20,
        display: 'flex',
        alignItems: 'center',
        p: (theme) => theme.spacing(3, 2, 3, 3),
      }}
    >
      <Avatar alt={nombre} src={''} sx={{ width: 48, height: 48, mr: 2 }} />

      <ListItemText
        primary={nombre}
        secondary={
          <span>
            {`Identificacion : ${ID_TYPE.find((option) => option.code === tipoIdentificacion)?.name || ''}`} <br />
            {`Moroso : ${situacion?.moroso}`} <br />
            {`Omiso : ${situacion?.omiso}`} <br />
            {`Estado : ${situacion?.estado}`} <br />
            {`administracionTributaria : ${situacion?.administracionTributaria}`} <br />
            {`Actividades : ${situacion?.Actividades || ''}`} <br />
          </span>
        }
        primaryTypographyProps={{
          noWrap: true,
          typography: 'subtitle2',
        }}
        secondaryTypographyProps={{
          mt: 0.5,
          noWrap: true,
          display: 'flex',
          component: 'span',
          alignItems: 'center',
          typography: 'caption',
          color: 'text.disabled',
        }}
      />

      <Button
        size="small"
        variant={selected ? 'text' : 'outlined'}
        color={selected ? 'success' : 'inherit'}
        startIcon={selected ? <Iconify width={18} icon="eva:checkmark-fill" sx={{ mr: -0.75 }} /> : null}
        onClick={onSelectedMethod}
        sx={{ flexShrink: 0, ml: 1.5 }}
      >
        {onSelected ? 'detalle' : 'agregar'}
      </Button>
    </Card>
  );
}
