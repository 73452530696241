import {
  Box,
  Card,
  CardContent,
  FormControlLabel,
  Grid,
  Modal,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import firebase from 'firebase/app';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { updateBranch, updateCompany, createNewCompany, createNewBranch } from '../../redux/slices/company';
import { UploadAvatar } from '../upload';
import useLocales from '../../hooks/useLocales';

const countries = [
  { code: 'mx', label: 'México' },
  { code: 'cr', label: 'Costa Rica' },
  { code: 'gt', label: 'Guatemala' },
];

const NewCompanyModal = ({
  openModal,
  setOpenModal,
  setSuccess,
  success,
  company,
  setIsEdit,
  isEdit,
  type,
  parentCompany,
  onBranchCreated,
}) => {
  const dispatch = useDispatch();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar } = useSnackbar();
  const user = useMemo(() => firebase.auth().currentUser, []);
  const [isPersonal, setIsPersonal] = useState(false);
  const { translate } = useLocales();
  const isCompany = type === 'company';

  const isDuplicate = (!isCompany && company.name) || (isCompany && company.companyName);
  const isEditOrDuplicate = isEdit || isDuplicate;

  const types = [
    { code: 'Legal', label: 'Legal' },
    { code: 'Contable', label: translate('Table.button15') },
    { code: 'Medico', label: translate('Table.button16') },
    { code: 'eCommerce', label: 'eCommerce' },
    { code: 'Operaciones', label: translate('Table.button17') },
    { code: 'Otros', label: translate('Table.button18') },
  ];

  const companySchema = {
    companyName: Yup.string().required('Company Name is required'),
    comercialName: Yup.string().required('Comercial Name is required'),
    email: Yup.string().email().required('E-Mail is required'),
    phone: Yup.number().required('Number is required'),
    companyType: Yup.string().required('Company Type is required'),
    country: Yup.string().required('Country is required'),
    firstName: isPersonal && Yup.string().required('First Name is required'),
    lastName: isPersonal && Yup.string().required('Last Name is required'),
  };
  const branchSchema = {
    name: Yup.string().required('Name is required'),
    address: Yup.string().required('Address is required'),
    phone: Yup.number().required('Number is required'),
  };
  const urlImageDefaultCompany = '/static/company/avatar.png';
  const urlImageDefaultBrach = '/static/brand/brand.svg';
  const urlImageDefaultCompanyCover = '/static/company/company.jpg';
  const urlImageDefaultBrachCover = '/static/brand/brandDefaultCover.png';

  const getCompanyName = () => {
    if (isEdit) {
      return company.companyName || '';
    }
    if (isDuplicate) {
      return `Copy ${company.companyName}` || '';
    }
    return '';
  };

  const getBranchName = () => {
    if (isEdit) {
      return company.name || '';
    }
    if (isDuplicate) {
      return `Copy ${company.name}` || '';
    }
    return '';
  };

  const companyInitialValues = {
    companyName: getCompanyName(),
    comercialName: isEditOrDuplicate ? company.comercialName || '' : '',
    phone: isEditOrDuplicate ? company.phone || '' : '',
    email: isEditOrDuplicate ? company.email || '' : '',
    companyType: isEditOrDuplicate ? company.companyType || 'company' : 'company',
    photoURL: isEditOrDuplicate ? company.photoURL || '' : urlImageDefaultCompany,
    photoURLCover: isEditOrDuplicate ? company.photoURL || '' : urlImageDefaultCompanyCover,
    authorizedUsersIds: company.authorizedUsersIds || [user.uid],
    rootUser: company.rootUser || user.uid,
    log: isEditOrDuplicate ? company.log || [] : [],
    menu: isEditOrDuplicate ? company.menu || false : true,
    country: company.country || 'cr',
  };

  const branchInitialValues = {
    name: getBranchName(),
    address: isEditOrDuplicate ? company.address || '' : '',
    phone: isEditOrDuplicate ? company.phone || '' : '',
    type: isEditOrDuplicate ? company.type || 'Legal' : 'Legal',
    photoURL: isEditOrDuplicate ? company.photoURL || '' : urlImageDefaultBrach,
    photoURLCover: isEditOrDuplicate ? company.photoURL || '' : urlImageDefaultBrachCover,
    authorizedUsersIds: company.authorizedUsersIds || [user.uid],
    rootUser: company.rootUser || user.uid,
    history: isEditOrDuplicate ? company.history || [] : [],
    parentCompanyId: parentCompany?.id || '',
  };

  const createSchema = Yup.object().shape(isCompany ? companySchema : branchSchema);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: isCompany ? companyInitialValues : branchInitialValues,
    validationSchema: createSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        let branchId = '';
        if (isEdit) {
          const updateFunction = isCompany ? updateCompany : updateBranch;
          branchId = await dispatch(updateFunction({ ...company, ...values, id: company.id }, user.uid));
        } else {
          const createFunction = isCompany ? createNewCompany : createNewBranch;
          branchId = await dispatch(createFunction(values));
        }
        setSuccess(!success);
        enqueueSnackbar(
          `Company : ${isCompany ? values.companyName : values.name} was ${
            isEdit ? 'Updated' : 'Created'
          } successfully`,
          { variant: 'success' }
        );
        if (isMountedRef.current) {
          setSubmitting(false);
        }
        if (onBranchCreated) {
          values.id = branchId;
          onBranchCreated(isEdit, values);
        }
      } catch (error) {
        if (isMountedRef.current) {
          setErrors({ afterSubmit: error.code });
          setSubmitting(false);
        }
      }
      handleClose();
    },
  });

  const handleClose = () => {
    setIsEdit(false);
    setOpenModal(false);
  };

  const { values, errors, touched, isSubmitting, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <Modal
      open={openModal}
      onClose={() => handleClose()}
      aria-labelledby="form-dialog-title"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        pl: 20,
        pr: 20,
      }}
      BackdropProps={{
        transitionDuration: 2000,
      }}
    >
      <Grid>
        <FormikProvider value={formik}>
          <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <Card>
                  <Box
                    sx={{
                      my: 2,
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                    }}
                  >
                    <UploadAvatar value={values.photoURL} onChange={(value) => setFieldValue('photoURL', value)} />
                    {isCompany && (
                      <>
                        <Typography>Company type</Typography>
                        <FormControlLabel
                          control={
                            <Switch
                              {...getFieldProps('companyType')}
                              checked={values.companyType === 'personal'}
                              onChange={() => {
                                const newValue = values.companyType === 'company' ? 'personal' : 'company';

                                setFieldValue('companyType', newValue);
                                setIsPersonal(!isPersonal);
                              }}
                              color="primary"
                            />
                          }
                          labelPlacement="bottom"
                          label="Company | Personal"
                        />
                      </>
                    )}
                  </Box>
                </Card>
              </Grid>

              <Grid item xs={12} md={8}>
                <Card sx={{ pb: 6 }}>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          error={
                            isCompany
                              ? Boolean(touched.companyName && errors.companyName)
                              : Boolean(touched.name && errors.name)
                          }
                          helperText={
                            isCompany ? touched.companyName && errors.companyName : touched.name && errors.name
                          }
                          label={translate('register.firstName')}
                          {...getFieldProps(isCompany ? 'companyName' : 'name')}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label={isCompany ? 'Comercial Name' : translate('direct.message')}
                          error={
                            isCompany
                              ? Boolean(touched.comercialName && errors.comercialName)
                              : Boolean(touched.address && errors.address)
                          }
                          helperText={
                            isCompany
                              ? touched.comercialName && errors.comercialName
                              : touched.address && errors.address
                          }
                          {...getFieldProps(isCompany ? 'comercialName' : 'address')}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label={translate('numero.button')}
                          error={Boolean(touched.phone && errors.phone)}
                          helperText={touched.phone && errors.phone}
                          {...getFieldProps('phone')}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <TextField
                          disabled={isEdit}
                          select
                          fullWidth
                          label={translate('type.message')}
                          placeholder="Type"
                          {...getFieldProps('type')}
                          SelectProps={{ native: true }}
                        >
                          {types.map((option) => (
                            <option key={option.code} value={option.code}>
                              {option.label}
                            </option>
                          ))}
                        </TextField>
                      </Grid>

                      {isCompany && (
                        <Grid item xs={12} sm={6}>
                          <TextField
                            fullWidth
                            label={translate('register.email')}
                            error={Boolean(touched.email && errors.email)}
                            helperText={touched.email && errors.email}
                            {...getFieldProps('email')}
                          />
                        </Grid>
                      )}

                      {isCompany && (
                        <Grid item xs={12}>
                          <TextField
                            select
                            fullWidth
                            label={translate('register.country')}
                            placeholder="País"
                            {...getFieldProps('country')}
                            SelectProps={{ native: true }}
                            error={Boolean(touched.language && errors.language)}
                            helperText={touched.language && errors.language}
                          >
                            {countries.map((option) => (
                              <option key={option.code} value={option.code}>
                                {option.label}
                              </option>
                            ))}
                          </TextField>
                        </Grid>
                      )}

                      {values.companyType === 'personal' && (
                        <>
                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label={translate('register.firstName')}
                              error={Boolean(touched.firstName && errors.firstName)}
                              helperText={touched.firstName && errors.firstName}
                              {...getFieldProps('firstName')}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <TextField
                              fullWidth
                              label={translate('register.lastName')}
                              error={Boolean(touched.lastName && errors.lastName)}
                              helperText={touched.lastName && errors.lastName}
                              {...getFieldProps('lastName')}
                            />
                          </Grid>
                        </>
                      )}
                    </Grid>

                    <Box
                      sx={{
                        mt: 3,
                        display: 'flex',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <LoadingButton type="submit" variant="contained" pending={isSubmitting}>
                        {translate('guardar.button')}
                      </LoadingButton>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </Grid>
    </Modal>
  );
};

NewCompanyModal.propTypes = {
  openModal: PropTypes.bool,
  setOpenModal: PropTypes.func,
  success: PropTypes.bool,
  setSuccess: PropTypes.func,
  company: PropTypes.object,
  setIsEdit: PropTypes.func,
  isEdit: PropTypes.bool,
  type: PropTypes.string,
  parentCompany: PropTypes.object,
  onBranchCreated: PropTypes.func,
};

export default NewCompanyModal;
