import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import profileIcon from '@iconify/icons-gg/profile';

import { Box, Button, Divider, MenuItem, Typography, ListSubheader, Link } from '@material-ui/core';
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Iconify from '../../components/Iconify';
import { MIconButton } from '../../components/@material-extend';
import MenuPopover from '../../components/MenuPopover';
import useLocales from '../../hooks/useLocales';
import MyAvatar from '../../components/MyAvatar';
import MyOrganizations from '../../views/Organization/MyOrganizationsnavbar';
import MySucursal from '../../views/Organization/MySucursal';
import CustomDialog from '../../components/ui/Dialog/CustomDialog';
import useAuth from '../../hooks/useAuth';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import { PATH_DASHBOARD } from '../../routes/paths';
import { deleteCompany, getCompaniesBybranch, getCompany } from '../../services/CompanyService';

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/',
  },
  {
    label: 'Mi perfil',
    icon: personFill,
    linkTo: PATH_DASHBOARD.user.profile,
  },
];

export default function AccountPopover2() {
  const history = useHistory();
  const { myProfile } = useSelector((state) => state.user);
  const [branches, setBranches] = useState([]);
  const { translate } = useLocales(); // Usamos el hook para traducir
  console.log('branches1', branches);
  const [openModal3, setOpenModal3] = useState(false);
  const openDialog3 = () => setOpenModal3(true);
  const anchorRef = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const isMountedRef = useIsMountedRef();
  const { logout } = useAuth();
  const [open, setOpen] = useState(false);
  const { currentCompany } = useSelector((state) => state.company);
  const { currentBranch } = useSelector((state) => state.company);
  const [openModal2, setOpenModal2] = useState(false);
  const openDialog2 = () => setOpenModal2(true);
  const closeDialog3 = () => setOpenModal3(false);
  console.log('branches12', currentCompany);

  const AccountStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    margin: theme.spacing(1, 2.5, 1),
    borderRadius: theme.shape.borderRadiusSm,
    backgroundColor: theme.palette.grey[500_12],
  }));

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      await logout();
      if (isMountedRef.current) {
        history.push('/');
        handleClose();
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const companies = await getCompaniesBybranch(currentCompany.id, currentBranch.id);
        const companiesToShow = companies.filter((el) => !el.isDisabled);
        setBranches(companiesToShow);
      } catch (err) {
        console.error(err);
      }
    };

    if (myProfile) {
      fetchCompanies();
    }
  }, [myProfile]);
  return (
    <>
      <div>
        <ListSubheader
          disableSticky
          disableGutters
          sx={{
            mt: 2,
            mb: 2,
            pl: 4,
            color: 'text.primary',
            typography: 'subtitle1',
          }}
        >
          {/* {`${myProfile.firstName} ${myProfile.lastName}`} */}
          {currentCompany.id && (
            <Link
              underline="none"
              component={RouterLink}
              ref={anchorRef}
              onClick={handleOpen}
              // to={PATH_DASHBOARD.administration.management}
              // to={`${PATH_DASHBOARD.administration.company.root}/${company.id}`}
            >
              <AccountStyle>
                <Box sx={{ ml: 0.5 }}>
                  <Typography variant="subtitle1" sx={{ color: 'text.primary' }}>
                    {currentCompany.name}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ color: 'text.secondary' }}>
                    {currentBranch.name}
                  </Typography>
                </Box>
                <Box sx={{ ml: 1.5 }}>
                  <MyAvatar company={currentCompany} />
                </Box>
              </AccountStyle>
            </Link>
          )}
        </ListSubheader>
      </div>

      <MenuPopover open={open} onClose={handleClose} anchorEl={anchorRef.current} sx={{ width: 220 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography sx={{ fontSize: 17 }} variant="subtitle1" noWrap>
            {`${currentCompany.name} `}
          </Typography>
        </Box>

        <Divider sx={{ my: 1 }} />

        {/* Contenedor para que MyOrganizations tenga scroll si es necesario */}
        <Box sx={{ overflowY: 'auto', px: 2, mt: -3 }}>
          <MyOrganizations />
        </Box>

        <Box sx={{ p: 2, pt: 1.5, mt: -4.3 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={openDialog3}>
            Mis organizaciones
          </Button>

          <CustomDialog
            open={openModal3}
            title={
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    ml: 4,
                    mb: -6,
                  }}
                >
                  Organizaciones
                </Typography>
              </Box>
            }
            onHide={closeDialog3}
            maxWidth="md"
            needCancelButton={false}
          >
            <Button
              variant="contained"
              onClick={openDialog2}
              component={RouterLink}
              sx={{
                zIndex: 1,
                mb: -13,
                ml: 83,
              }}
              startIcon={<Iconify icon="mingcute:add-line" />}
            >
              {translate('welcome2.button')}
            </Button>
            <MySucursal />
          </CustomDialog>
        </Box>
      </MenuPopover>
    </>
  );
}
