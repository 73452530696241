import { useTranslation } from 'react-i18next';
import React, { useEffect } from 'react';

// ----------------------------------------------------------------------

const LANGS = [
  {
    value: 'es',
    label: 'ES',
    icon: '/static/icons/ic_flag_es.svg',
  },
  {
    value: 'en',
    label: 'EN',
    icon: '/static/icons/ic_flags_us.svg',
  },
];

// ----------------------------------------------------------------------

export default function useLocales() {
  const { i18n, t: translate } = useTranslation();
  const langStorage = localStorage.getItem('i18nextLng');
  const currentLang = LANGS.find((_lang) => _lang.value === langStorage) || {};

  const handleChangeLanguage = (newlang) => {
    console.log('Cambiando idioma a:', newlang);
    i18n.changeLanguage(newlang);
  };

  useEffect(() => {
    console.log('Idioma actual:', currentLang.value);
  }, [currentLang]);

  return {
    onChangeLang: handleChangeLanguage,
    translate,
    currentLang,
    allLang: LANGS,
  };
}
