import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';

// eslint-disable-next-line arrow-body-style
const CustomDialog = ({
  open,
  title,
  onConfirm,
  onHide,
  children,
  maxWidth,
  needCancelButton,
  cancelTextButton,
  confirmTextButton,
  sx = {},
}) => {
  const needActions = needCancelButton || onConfirm;
  return (
    <Dialog open={open} onClose={onHide} fullWidth={Boolean(true)} maxWidth={maxWidth} sx={sx}>
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>{children}</DialogContent>
      {needActions && (
        <DialogActions>
          {needCancelButton && onHide && <Button onClick={onHide}>{cancelTextButton || 'Cancelar'}</Button>}
          {onConfirm && (
            <Button variant="contained" onClick={onConfirm}>
              {confirmTextButton || 'Guardar'}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  );
};

CustomDialog.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.any, // string or node
  onConfirm: PropTypes.func,
  onHide: PropTypes.func,
  children: PropTypes.any,
  maxWidth: PropTypes.string,
  needCancelButton: PropTypes.bool,
  cancelTextButton: PropTypes.string,
  confirmTextButton: PropTypes.string,
  sx: PropTypes.object,
};

export default CustomDialog;
