import firebase from 'firebase/app';

const CHAT_ASSISTANTS_BASE_URL = 'chat_assistants_branch';

export const getChatAssistantsByBranch = async (branchId) =>
  firebase
    .database()
    .ref(`${CHAT_ASSISTANTS_BASE_URL}/${branchId}`)
    .once('value')
    .then((snap) => Object.values(snap.val() || {}));

export const createChatAssistant = async (branchId, chatAssistant) => {
  const rootRef = firebase.database().ref('/');
  const assistantsRef = rootRef.child(`${CHAT_ASSISTANTS_BASE_URL}/${branchId}`);

  const assistantId = (await assistantsRef.push(chatAssistant)).key;

  // update id
  const updates = {};
  updates[`${CHAT_ASSISTANTS_BASE_URL}/${branchId}/${assistantId}/id`] = assistantId;
  await firebase.database().ref().update(updates);

  return assistantId;
};

export const updateChatAssistant = async (branchId, chatAssistant) => {
  const updates = {};
  updates[`${CHAT_ASSISTANTS_BASE_URL}/${branchId}/${chatAssistant.id}`] = chatAssistant;
  await firebase.database().ref().update(updates);
};

export const updateChatAssistantFiles = async (branchId, chatAssistantId, newFiles) => {
  const updates = {};
  updates[`${CHAT_ASSISTANTS_BASE_URL}/${branchId}/${chatAssistantId}/files`] = newFiles;
  await firebase.database().ref().update(updates);
};

export const updateChatAssistantKnowledgeBase = async (branchId, chatAssistantId, knowledgeBases) => {
  const updates = {};
  updates[`${CHAT_ASSISTANTS_BASE_URL}/${branchId}/${chatAssistantId}/knowledgeBases`] = knowledgeBases;
  await firebase.database().ref().update(updates);
};

export const deleteChatAssistant = async (branchId, chatAssistantId) => {
  const ratingRef = firebase.database().ref(`/${CHAT_ASSISTANTS_BASE_URL}/${branchId}/${chatAssistantId}`);
  await ratingRef.remove();
};

export const addLLMDataToChatAssistant = async (branchId, chatAssistantId, llm) => {
  const updates = {};
  updates[`${CHAT_ASSISTANTS_BASE_URL}/${branchId}/${chatAssistantId}/llm`] = llm;
  await firebase.database().ref().update(updates);
};

export const publishChatAssistant = async (branchId, chatAssistant) => {
  const updates = {};
  updates[`${CHAT_ASSISTANTS_BASE_URL}/${branchId}/${chatAssistant.id}`] = chatAssistant;
  await firebase.database().ref().update(updates);
};

export const addLLMToChatAssistant = async (branchId, chatAssistant, llm) => {
  const updates = {};
  updates[`${CHAT_ASSISTANTS_BASE_URL}/${branchId}/${chatAssistant.id}/llm`] = llm;
  await firebase.database().ref().update(updates);
};

export const getChatAssistant = async (branchId, chatAssistantId) =>
  firebase
    .database()
    .ref(`${CHAT_ASSISTANTS_BASE_URL}/${branchId}/${chatAssistantId}`)
    .once('value')
    .then((snap) => Object.values(snap.val() || {}));
